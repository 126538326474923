import React, {useEffect} from "react";
import "./client-terms-user-privacy-policy.scss"
import ModalContainer from "../../ModalContainer";
import logo from "../../../assets/images/logo.svg"
import customAxios from "../../../service/api/interceptors";
import {CompanyType} from "../../../types/company.type";
import {useIntercom} from "react-use-intercom";

export const ClientTermsUsePrivacyPolicy: React.FC<{ companies: CompanyType[] }> = ({ companies }) => {
    const [isModalVisible, setIsModalVisible] = React.useState(false);
    const [companyId, setCompanyId] = React.useState<string>("");
    const { showNewMessages } = useIntercom()
    const [link, setLink] = React.useState("");

    const getData = async () => {
        const companyHasBeenAcceptTerms = companies.find(el => el.is_terms_accepted === 0)
        if (companyHasBeenAcceptTerms) {
            const { status, data } = await customAxios.get("/company/terms-conditions-url")
            if (status === 200 && data?.terms_conditions_url) {
                setLink(data?.terms_conditions_url)
                setCompanyId(companyHasBeenAcceptTerms.id)
                setIsModalVisible(true)
            }
        }
    }

    useEffect(() => {
        if (companies.length > 0) getData().then()
    }, [companies]);

    const termsAccepted = async () => {
        const { status } = await customAxios.post(`/company/${companyId}/terms-accepted`)
        if (status === 200) {
            setIsModalVisible(false);
            setCompanyId("")
        }
    }

    return (
        <div className={"client-terms-user-privacy-policy"}>
            <ModalContainer isOpen={isModalVisible} close={() => {}} hideClose={true}>
                <div>
                    <h4>Terms of Service</h4>
                    <img src={logo} alt=""/>
                    <h2>Update to the Terms of Service</h2>
                    <p>
                        We truly appreciate you being a part of Legal Nodes. <br />
                        To keep improving your experience, we’ve updated our Terms of Service and included more details
                        on how our credit system works.
                    </p>
                    <p>
                        You can review the updated terms <a target={"_blank"} href={link}>here</a>. To continue using Legal Nodes, simply click
                        ‘Accept & Continue’. By doing so, you acknowledge and agree to the updated Terms of Service.
                        If you have any questions or need assistance, our Support Team is happy to assist you.
                        Just click ‘Chat with Support’  or email us at at <a target={"_blank"} href="mailto:help@legalnodes.com">help@legalnodes.com</a>.
                    </p>
                    <p>Thanks for being with us!</p>
                    <button onClick={termsAccepted} className={"btn-black w100 text-uppercase mt25"}>
                        ACCEPT & CONTINUE
                    </button>
                    <button onClick={() => showNewMessages("")} className={"btn-white w100 text-uppercase mt10"}>
                        Chat WITH SUPPORT
                    </button>
                </div>
            </ModalContainer>
        </div>
    )
}