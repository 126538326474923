import ModalContainer from "../../../components/ModalContainer";
import "./delete-follow-up-modal-confirm.scss"
import deleteIcon from "../icons/deleteIcon.svg"
import React from "react";

type Props = {
    confirm: () => void
    close: () => void
    isOpen: boolean
}

export const DeleteFollowUpModalConfirm: React.FC<Props> = ({ isOpen, confirm, close }) => {
    return (
        <div className={"delete-follow-up-modal-confirm"}>
            <ModalContainer isOpen={isOpen} close={close} heading={""} hideClose={true}>
                <div>
                    <h2>DELETE CALL</h2>
                    <img src={deleteIcon} alt=""/>
                    <p>
                        Calls are deleted permanently along with the follow-up information.
                        Are you sure you want to delete it?
                    </p>
                    <button
                        onClick={confirm}
                        className={"delete-follow-up-modal-confirm__ok btn-black w100 mt30 text-uppercase"}
                    >Yes, proceed</button>
                    <button
                        onClick={close}
                        className={"delete-follow-up-modal-confirm__cancel w100 btn-white mt10 text-uppercase"}
                    >No, go back</button>
                </div>
            </ModalContainer>
        </div>
    )
}