import React from "react";
import {PartnerRequestType} from "../PartnerRequestType";

type Props = {
    data: PartnerRequestType
    acceptPublishRequest: boolean
    updatePartnerRequest: (data: any) => void
}

export const PublishPartnerRequest: React.FC<Props> = ({ data, acceptPublishRequest, updatePartnerRequest }) => {
    return (
        <div className={'publish-partner-request'}>
            <div>
                {acceptPublishRequest && <p>
                    By clicking “Submit”, you will send details for this project to the
                    Virtual Legal Officer who will pass this information on to the client.
                </p>}
            </div>

            <div className={'publish-partner-request__right'}>
                {+data?.credit_total > 0 &&
                    <h4 style={{ marginBottom: '5px' }}>Total credit price: <b>{data?.credit_total} <span style={{ fontSize: "16px" }}>{`credit${data?.credit_total > 1 ? 's' : ''}`}</span></b>
                </h4>}
                <h4>Total price: <b>${data.total_price}</b></h4>
                <button
                    onClick={() => updatePartnerRequest({ status: '3' })}
                    disabled={!acceptPublishRequest}
                    className={'btn-black w100 text-uppercase'}>SUBMIT{+data?.status >= 3 && 'TED'}</button>
            </div>
        </div>
    )
}



export const AcceptFromVLOPartnerRequest: React.FC<Props> = ({ data, acceptPublishRequest, updatePartnerRequest }) => {
    console.log("data?.credit_totaldata?.credit_total", data?.credit_total)
    return (
        <div className={'publish-partner-request'}>
            <div className="partner-request-payment-confirm__body" style={{ padding: 0, width: '100%' }}>
                <div className="partner-request-payment-confirm__body-table">
                    <div className="partner-request-payment-confirm__body-td" style={{ padding: '33px 0' }}>
                        {acceptPublishRequest && <p>
                            Clicking “ACCEPT” will add the list of tasks
                            and provided estimates to the main list of tasks
                            for this offer.
                        </p>}
                    </div>
                    <div style={{ padding: '14px 20px', flexDirection: 'column' }}>
                        {+data?.credit_total > 0 &&<h4>Total credit price: <b>{data?.credit_total} {`credit${data?.credit_total > 1 ? 's' : ''}`}</b>
                        </h4>}
                        <h4>Total price: <b>${data?.total_price}</b></h4>
                        <button disabled={!acceptPublishRequest} onClick={() => updatePartnerRequest({ status: '4' })} className={'btn-black text-uppercase w100'}>ACCEPT</button>
                    </div>
                </div>
            </div>
        </div>
    )
}