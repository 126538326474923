import "./no-calls-screen.scss"
import {useHistory} from "react-router-dom";

export const NoCalls = () => {
    const history = useHistory();

    return (
        <div className={"no-calls-screen no-calls-screen-all"}>
            <p>
                You do NOT have ANY UPCOMING CALLS YET. <br />
                book a new CALL DIRECTLY HERE!
            </p>
            <button onClick={() => history.push(`/book-new-calls`)}>
                book A CALL
            </button>
        </div>
    )
}