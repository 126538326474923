import plusIcon from "../../icons/count-plus.svg"
import minusIcon from "../../icons/count-minus.svg"
import React from "react";
import {BookNewFollowUpTypesItemType} from "../../BookNewFollowUp/useBookNewFollowUp";
import {useHistory} from "react-router-dom";

type ChooseCallsProps = {
    count: number
    setCount: (count: number) => void
    item: BookNewFollowUpTypesItemType
    totalCredits: number
    onClick: () => void
}

export const ChooseCalls: React.FC<ChooseCallsProps> = ({ count, item, setCount, totalCredits, onClick }) => {
    const history = useHistory();
    const total = +item.credit_price * count

    const inc = () => setCount(count + 1)
    const dec = () => {
        if (count != 1) {
            setCount(count - 1)
        }
    }

    return (
        <div className={"by-new-call-modal__list"}>
            <div className={"by-new-call-modal__list-heading"}>
                <h4>Choose calls</h4>
                <div className={"by-new-call-modal__list-heading-count"}>
                    <span>Your Credits Balance:</span>&nbsp;<b>{totalCredits}</b>
                </div>
            </div>
            <div className="by-new-call-modal__items">
                <div className={"by-new-call-modal__item"}>
                    <div className={"by-new-call-modal__item-heading"}>
                        <div className={"by-new-call-modal__item-heading-left"}>
                            <div className="by-new-call-modal__item-counter">
                                <img onClick={dec} src={minusIcon} alt=""/>
                                <span>{count}</span>
                                <img onClick={inc} src={plusIcon} alt=""/>
                            </div>
                            <em>{item.title}</em>
                        </div>
                        <div>
                            <em>{item.credit_price} credit{+item.credit_price > 1 && "s"}/{item.duration}</em>
                        </div>
                    </div>
                    <p className={"by-new-call-modal__item-content"}>
                        <div dangerouslySetInnerHTML={{ __html: item.description }} />
                    </p>
                </div>
            </div>
            <div className="by-new-call-modal__total-price">
                Total: {total} credit{total > 1 && "s"}
            </div>
            {(total > +totalCredits) && <div className="by-new-call-modal__none-credits">
                You don't have enough credits. <br/>
                Please top up your credit balance.
            </div>}
            {!(total > +totalCredits) && <button onClick={onClick} className="btn-black w100 mt30 text-uppercase">Confirm & redeem credits</button>}
            {(total > +totalCredits) && <button onClick={() => history.push(`/settings/payment-plan?count=${+total - +totalCredits}`)} className="btn-black w100 mt30 text-uppercase">BUY credits</button>}
        </div>
    )
}