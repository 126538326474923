import React, {useRef, useState} from "react";
import {useOnClickOutside} from "../../../hooks/useOnClickOutside";
import toggleImage from "../icons/toggle.svg";
import {CSSTransition} from "react-transition-group";
import {columnsWidth} from "../FollowUpList/FollowUpNewCalls/FollowUpNewCalls";
import {FollowUpType} from "../FollowUpList/FollowUpList";
import moment from "moment";

export const FollowUpNewCallsUpcomingItem: React.FC<{ item: FollowUpType }> = ({ item }) => {
    return (
        <div className="follow-up-list-item">
            <div className="follow-up-list-item-td" style={columnsWidth[0]}>
                <b>{item.call_type.title}</b>
                <p>{item.call_type.subtitle}</p>
            </div>
            <div className="follow-up-list-item-td" style={columnsWidth[1]}>
                <p>{item.date ? moment(item.date * 1000).format("DD/MM/YYYY") : "-"}</p>
            </div>
            <div className="follow-up-list-item-td" style={columnsWidth[2]}>
                <p>{item.date ? moment(item.date * 1000).format("HH:mm") : "-"}</p>
            </div>
            <div className="follow-up-list-item-td flex alignCenter" style={columnsWidth[3]}>
                <a className={"w100"} target={"_blank"} href={item.link}>
                    <button className={"btn-black w100 text-uppercase mr10"}>
                        JOIN
                    </button>
                </a>
                {/*<FollowUpItemToggle />*/}
            </div>
        </div>
    )
}

const FollowUpItemToggle: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false)
    const dropdownRef = useRef<HTMLDivElement>(null)
    useOnClickOutside({ ref: dropdownRef, handler: () => setIsOpen(false) })

    return (
        <div ref={dropdownRef} className="follow-up-list-item-toggle">
            <img onClick={() => setIsOpen(!isOpen)} src={toggleImage} alt=""/>
            <CSSTransition in={isOpen} timeout={{ enter: 200, exit: 200 }} classNames={'my-node'} unmountOnExit>
                <div>
                    <div className="follow-up-list-item-toggle-squire" />
                    <div className="follow-up-list-item-toggle-dropdown">
                        <div>Edit</div>
                    </div>
                </div>
            </CSSTransition>
        </div>
    )
}