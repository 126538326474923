import React, {useContext, useEffect, useState} from 'react'
import './client_kanban_request.scss'
import createRequestIcon from '../../../assets/images/createRequest.svg'
import callWithManagerIcon from '../../../assets/images/callWithManager.svg'
import {MainContextProvider} from "../../../context/MainContext";
import { openPopupWidget } from "react-calendly";
import customAxios from "../../../service/api/interceptors";


export const ClientKanbanRequest: React.FC = () => {
    const { setModalType, userData, setModalData } = useContext(MainContextProvider)
    const [calendlyUrl, setCalendlyUrl] = useState('')

    const getUrl = async () => {
        const { data } = await customAxios.get('/onboarding/get-kanban-calendly-widget')
        setCalendlyUrl(data)
    }

    useEffect(() => {
        getUrl().then()
    }, [])

    return (
        <div>
            <h4>
                Select whether you're ready to submit a new request or prefer to book<br /> a call with our team first
            </h4>
            <div className={'client_kanban_request__body'}>
                <div className="client_kanban_request__col">
                    <div className="client_kanban_request__image">
                        <img src={createRequestIcon} alt=""/>
                    </div>
                    <div className="client_kanban_request__title">
                        <img style={{ display: 'none' }} src={createRequestIcon} alt=""/>
                        Create New Request
                    </div>
                    <div className="client_kanban_request__description">
                        Submit a new request by specifying your needs. Initiating any request costs 1 credit.
                    </div>
                    <button
                        onClick={() => {
                            setModalType('create_legal_request')
                        }}
                        className="client_kanban_request__button btn-black">
                        CREATE A NEW REQUEST
                    </button>
                </div>
                <div className="client_kanban_request__col">
                    <div className="client_kanban_request__image">
                        <img src={callWithManagerIcon} alt=""/>
                    </div>
                    <div className="client_kanban_request__title" style={{ textTransform: 'uppercase' }}>
                        <img style={{ display: 'none' }} src={callWithManagerIcon} alt=""/>
                        Book a call
                    </div>
                    <div className="client_kanban_request__description">
                        Would like to discuss your business need on a call? Book a call with the industry expert or with your VLO
                    </div>
                    <a href="/book-new-calls" style={{ textDecoration: "none" }}>
                        <button
                            className="client_kanban_request__button btn-black" style={{ textTransform: 'uppercase' }}>
                            Book a call
                        </button>
                    </a>
                </div>
            </div>
        </div>
    )
}