import "../follow-up-list.scss"
import {FollowUpTabs} from "../../components/FollowUpTabs/FollowUpTabs";
import React, {useEffect, useRef, useState} from "react";
import {NoCalls} from "../../components/NoCallsScreen/NoCalls";
import {NoUpcomingCalls} from "../../components/NoCallsScreen/NoUpcomingCalls";
import {FollowUpNewCallsUpcomingItem} from "../../components/FollowUpNewCallsUpcomingItem";
import {FollowUpNewCallAvailableItem} from "../../components/FollowUpNewCallAvailableItem";
import {useFollowUpNewCalls} from "./useFollowUpNewCalls";
import {ByNewCallModal} from "../../components/ByNewCallModal/ByNewCallModal";
import {BookNewFollowUpTypesItemType} from "../../BookNewFollowUp/useBookNewFollowUp";
import {CustomLoader} from "../../../../components/UI/CustomLoader/CustomLoader";
import {NavLink} from "react-router-dom";

export const columnsWidth = [{ width: "40%" }, { width: "20%" }, { width: "20%" }, { width: "40%" }]
export const columnsAvailableWidth = [{ width: "40%" }, { width: "20%" }, { width: "20%" }, { width: "40%" }]

export type CallListTypes = {
    call_id: string,
    type: string,
    count: string,
    company_id: string,
    call_type: BookNewFollowUpTypesItemType
}

export const FollowUpNewCalls = () => {
    const {
        upcomingCalls,
        availableCalls,
        bookCall,
        loader
    } = useFollowUpNewCalls()

    return (
        <div className={"follow-up-list"}>
            <div className="follow-up-list__content">
                <h1>Expert Calls Hub</h1>
                <FollowUpTabs />
                {loader && <CustomLoader />}
                {!loader && <>
                    {upcomingCalls.length > 0 && <h2>Upcoming calls</h2>}
                    {upcomingCalls.length === 0 && availableCalls.length > 0 && <NoUpcomingCalls/>}
                    {upcomingCalls.length > 0 && <div className="follow-up-list__table">
                        <div className="follow-up-list__table-tr">
                            <div className="follow-up-list__table-th" style={columnsWidth[0]}>Type</div>
                            <div className="follow-up-list__table-th" style={columnsWidth[1]}>Date</div>
                            <div className="follow-up-list__table-th" style={columnsWidth[2]}>Time</div>
                            <div className="follow-up-list__table-th" style={columnsWidth[3]}></div>
                        </div>
                        {
                            (upcomingCalls || []).map((call) => (
                                <FollowUpNewCallsUpcomingItem
                                    key={call.id}
                                    item={call}
                                />
                            ))
                        }

                    </div>}

                    {availableCalls.length > 0 && <div className="follow-up-list-item__need-book">
                        <h2>Remaining calls</h2>
                        <span>Need to book more calls? <NavLink to="/book-new-calls">Click here</NavLink></span>
                    </div>}
                    {availableCalls.length === 0 && <NoCalls/>}
                    {availableCalls.length > 0 && <div className="follow-up-list__table">
                        <div className="follow-up-list__table-tr">
                            <div className="follow-up-list__table-th" style={columnsAvailableWidth[0]}>Type</div>
                            <div className="follow-up-list__table-th" style={columnsAvailableWidth[1]}>Duration</div>
                            <div className="follow-up-list__table-th" style={columnsAvailableWidth[2]}>Quantity</div>
                            <div className="follow-up-list__table-th" style={columnsAvailableWidth[3]}></div>
                        </div>
                        {
                            availableCalls.map((call) => (
                                <FollowUpNewCallAvailableItem key={call.call_id}
                                    item={call}
                                    bookCall={() => bookCall(call?.call_type?.link, call.call_id)}
                                />
                            ))
                        }
                    </div>}
                </>}
            </div>
        </div>
    )
}