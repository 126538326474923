import React, {useContext, useRef, useState} from "react";
import toggleImage from "../icons/toggle.svg";
import reqIcon from "../../../assets/images/kanbanIcons/request.svg"
import {FollowUpCallTypes, followUpColumnsWidth, FollowUpType} from "./FollowUpList";
import {useOnClickOutside} from "../../../hooks/useOnClickOutside";
import {CSSTransition} from "react-transition-group";
import moment from "moment";
import {useHistory} from "react-router-dom";
import {MainContextProvider} from "../../../context/MainContext";

type FollowUpListItemType = {
    item: FollowUpType
    toggleButton: (key: string) => void
    isClient: boolean
}

export const FollowUpListItem: React.FC<FollowUpListItemType> = ({ item, toggleButton, isClient }) => {
    const { userData } = useContext(MainContextProvider)
    const history = useHistory()
    const isDisabledButton = userData?.status.type_code === 100 && +item.status === 0

    return (
        <div className={"follow-up-list-item"}>
            <div style={followUpColumnsWidth[0]} className="follow-up-list-item-td">
                <p>{item.business_goals.length >= 1 ? item.business_goals[0]?.title : ""}</p>
                <em>{item?.company?.name}</em>
            </div>
            <div style={followUpColumnsWidth[1]} className="follow-up-list-item-td">
                <p>{FollowUpCallTypes[item.type]}</p>
            </div>
            <div style={followUpColumnsWidth[2]} className="follow-up-list-item-td">
                <p>
                    {moment(item.date * 1000).format("DD/MM/YYYY")}<br />
                    {/*{moment(item.created_at * 1000).format("HH:mm")}*/}
                </p>
            </div>
            <div style={followUpColumnsWidth[3]} className="follow-up-list-item-td flex">
                {isDisabledButton && <div className={"follow-up-list-item-tooltip"}>
                    <div className={"follow-up-list-item-tooltip-icon"}>
                        <img src={reqIcon} alt=""/>
                    </div>
                    <span>This call follow-up is being prepared. You will be able to view it here once it's ready.</span>
                </div>}
                <button disabled={isDisabledButton} onClick={() => history.push(`/follow-ups/${item.id}`)} className={"follow-up-list-item-button mr10"}>
                    FOLLOW-up
                </button>
                {!isClient && <FollowUpItemToggle
                    toggleButton={toggleButton}
                />}
            </div>
        </div>
    )
}

const FollowUpItemToggle: React.FC<{ toggleButton: (key: string) => void }> = ({ toggleButton }) => {
    const [isOpen, setIsOpen] = useState(false)
    const dropdownRef = useRef<HTMLDivElement>(null)
    useOnClickOutside({ ref: dropdownRef, handler: () => setIsOpen(false) })

    return (
        <div ref={dropdownRef} className="follow-up-list-item-toggle">
            <img onClick={() => setIsOpen(!isOpen)} src={toggleImage} alt=""/>
            <CSSTransition in={isOpen} timeout={{ enter: 200, exit: 200 }} classNames={'my-node'} unmountOnExit>
                <div>
                    <div className="follow-up-list-item-toggle-squire" />
                    <div className="follow-up-list-item-toggle-dropdown">
                        {/*<div onClick={() => toggleButton("copy")}>Copy link</div>*/}
                        <div onClick={() => {
                            toggleButton("edit")
                            setIsOpen(false)
                        }}>Edit</div>
                        <div onClick={() => {
                            toggleButton("delete")
                            setIsOpen(false)
                        }} className={"follow-up-list-item-toggle-dropdown-red"}>Delete</div>
                    </div>
                </div>
            </CSSTransition>
        </div>
    )
}