import icon from "../../icons/thanks.svg"
import {useHistory} from "react-router-dom";
import React from "react";

export const ThanksComponent: React.FC<{ bookCall: () => void }> = ({ bookCall }) => {
    const history = useHistory();

    return (
        <div className={"by-new-call-modal__thanks"}>
            <img src={icon} alt=""/>
            <h5>You’re All Set!</h5>
            <p>
                You can book a suitable time by clicking on <b>“Book a Call Now”</b>.
            </p>
            <p>
                Not ready yet? No worries — click on <b>“Book Later”</b> and <br />
                schedule it within 1 month in the <b>“Upcoming & Remaining Calls”</b><br /> section!
            </p>
            <button onClick={bookCall} className={"btn-black w100 mt30 text-uppercase"}>BOOK A CALL NOW</button>
            <button onClick={() => history.push(`/upcoming-calls`)} className={"btn-white w100 mt10 text-uppercase"}>book later</button>

        </div>
    )
}