import {useContext, useEffect, useState} from "react";
import {MainContextProvider} from "../../../../context/MainContext";
import {useHistory, useLocation} from "react-router-dom";
import {CompanyType} from "../../../../types/company.type";
import {FollowUpType} from "../FollowUpList";
import customAxios from "../../../../service/api/interceptors";

export const useManagerFollowUpList = () => {
    const location = useLocation()
    const history = useHistory()
    const searchParams = new URLSearchParams(location.search);
    const { setLoading: setLoader, userData, creditCount } = useContext(MainContextProvider)
    const [companies, setCompanies] = useState<CompanyType[]>([])
    const [loading, setLoading] = useState(false)
    const [items, setItems] = useState<FollowUpType[]>([])
    const [count, setCount] = useState(0)
    const [modalConfig, setModalConfig] = useState<{ id?: number, data?: FollowUpType } | null>(null)
    const [clientCallLink, setClientCallLink] = useState("")
    const [isOpenDestroyModal, setIsOpenDestroyModal] = useState<null | string>(null)

    const getClientCallLing = async () => {
        if (userData?.status.type_code !== 100) return

        try {
            const { status, data } = await customAxios.get(`/onboarding/get-kanban-calendly-widget`)
            if (status === 200) setClientCallLink(data)
        } catch (e) {}
    }

    const getData = async () => {
        const dateMonthAgo = new Date();
        dateMonthAgo.setMonth(dateMonthAgo.getMonth() - 1);

        const { data: companyItems } = await customAxios.get(`/company/company/get-companies`)
        setCompanies(companyItems)

        const page = searchParams.get("page") || 1
        const start_date = searchParams.get("start_date") ? `&from_timestamp=${Number(searchParams.get("start_date")) / 1000}` : ``
        const end_date = searchParams.get("end_date") ? `&to_timestamp=${(Number(searchParams.get("end_date")) + 86400000) / 1000}` : ``
        const company = searchParams.get("company_id") ? `&company_id=${searchParams.get("company_id")}` : ''
        const order_direction = searchParams.get("sort_by") ? `&order_direction=${searchParams.get("sort_by")}` : '&order_direction=DESC'
        /*const types = searchParams.get("call_type") ? `&types[]=${searchParams.get("call_type")}` : ""*/
        const call_types: string[] | undefined = searchParams.get("call_type") ? searchParams.get("call_type")?.split(",") : []
        let types = ""
        if (call_types && Array.isArray(call_types)) {
            for (let type of call_types) {
                types += `&types[]=${type}`
            }
        }


        setLoading(true)
        try {
            const { status, data } = await customAxios.get(`/call/list?page=${page}&status_list[]=0&per_page_count=10${types}${start_date}${end_date}${company}${order_direction}`)
            if (status === 200) {
                setItems(data.calls)
                setCount(data.count)
            }
        } catch (e) {}
        setLoading(false)
    }

    useEffect(() => { getClientCallLing() },[])

    useEffect(() => {
        setLoader(false)
        document.title = "Calls & Follow-ups"
        getData()
    }, [location])

    const updateParam = (paramName: string, newValue: string) => {
        if (searchParams.has(paramName)) {
            searchParams.set(paramName, newValue);
        } else {
            searchParams.append(paramName, newValue);
        }
        history.push(`${window.location.pathname}?${searchParams.toString()}`);
    }

    const destroyItem = async () => {
        setLoader(true)
        try {
            const { status } = await customAxios.delete(`/call-follow-up/${isOpenDestroyModal}`)
            if (status === 200) {
                setItems(items.filter(el => String(el.id) !== isOpenDestroyModal))
                setIsOpenDestroyModal(null)
            }
        } catch (e) {}
        setLoader(false)
    }

    const makeConducted = async (call_id: string) => {
        setLoader(true)
        try {
            const { status, data } = await customAxios.post(`/call/${call_id}/conducted`)
            if (status === 200) {
                getData()
            }
        } catch (e) {}
        setLoader(false)
    }

    return {
        companies,
        items,
        loading,
        updateParam,
        searchParams,
        count,
        modalConfig,
        setModalConfig,
        getData,
        isClient: userData?.status.type_code === 100,
        clientCallLink,
        userData,
        isOpenDestroyModal,
        setIsOpenDestroyModal,
        destroyItem,
        creditCount,
        makeConducted
    }
}