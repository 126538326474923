import React, {useEffect} from 'react'
import { NavLink, Route, Switch } from 'react-router-dom'
import './settings.scss'
import userIcon from '../../assets/images/nav/user.svg'
import historyIcon from '../../assets/images/nav/history.svg'
import plansIcon from '../../assets/images/nav/plans.svg'
import docsIcon from '../../assets/images/nav/payment.svg'
import paymentMethodIcon from '../../assets/images/nav/payment.svg'
import AccountSettings from './Routes/AccountSettings'
import PaymentHistory from './Routes/PaymentHistory'
import Subscriptions from './Routes/Subscriptions'
import PaymentMethods from './Routes/PaymentMethods'
import NotFound from "../NotFound/NotFound";
import {CardPaymentMethod} from "./Routes/Subscriptions/CardPaymentMethod";
import ProviderPaymentHistory from "./Routes/PaymentHistory/ProviderPaymentHistory";
import {PaymentPlan} from "./Routes/PaymentPlan/PaymentPlan";
import {CryptoPaymentPage} from "./Routes/Checkout/CryptoPaymentPage/CryptoPaymentPage";
import {useSettings} from "./Routes/Subscriptions/useSettingsLogic";

const Settings = () => {
  const { role, userData, creditCount, showPaymentMethod } = useSettings();

  const isClientWithOnBoarding = role === 'client' && userData?.flows.onboarding === 1;
  const isUserStatus200 = userData?.status.type_code === 200;

  return (
      <div className="settings">
        <div className="settings__container">
          <div className="settings__body">
            <Switch>
              <Route path="/settings/account" component={AccountSettings} />
              {isUserStatus200 && (
                  <>
                    <Route path="/settings/history" component={ProviderPaymentHistory} />
                    <Route path="/settings/crypto-payment/:id" component={CryptoPaymentPage} />
                  </>
              )}
              {role === 'client' && (
                  <>
                    <Route path="/settings/history" component={PaymentHistory} />
                    <Route path="/settings/subscriptions" component={Subscriptions} />
                    <Route path="/settings/payment-methods" component={PaymentMethods} />
                    <Route path="/settings/stripe-payment-method/:id" component={CardPaymentMethod} />
                    <Route path="/settings/payment-plan" component={PaymentPlan} />
                    <Route path="/settings/crypto-payment/:id" component={CryptoPaymentPage} />
                  </>
              )}
              {role !== 'client' && <NotFound />}
            </Switch>
          </div>
          <div className="settings__nav">
            <NavLink to="/settings/account" className="settings__nav-item">
              <img src={userIcon} alt="Account Icon" />
              <span>Account Information</span>
            </NavLink>
            {isClientWithOnBoarding && (
                <NavLink to={creditCount.isCreditSubscription ? '/settings/payment-plan' : '/settings/subscriptions'} className="settings__nav-item">
                  <img src={plansIcon} alt="Plans Icon" />
                  <span>{creditCount.isCreditSubscription ? 'Manage My Contract & Credits' : 'Your Monthly Plans'}</span>
                </NavLink>
            )}
            {(isClientWithOnBoarding || isUserStatus200) && (
                <NavLink to="/settings/history" className="settings__nav-item">
                  <img src={historyIcon} alt="History Icon" />
                  <span>Billing History</span>
                </NavLink>
            )}
            {role === 'client' && showPaymentMethod && (
                <NavLink to={`/settings/stripe-payment-method/${showPaymentMethod}`} className="settings__nav-item">
                  <img src={docsIcon} alt="Documents Icon" />
                  <span>Change Payment Method</span>
                </NavLink>
            )}
          </div>
        </div>
      </div>
  );
};

export default Settings;