import {useContext, useEffect, useRef, useState} from "react";
import {MainContextProvider} from "../../../../context/MainContext";
import customAxios from "../../../../service/api/interceptors";
import {openPopupWidget} from "react-calendly";
import {CallListTypes} from "./FollowUpNewCalls";
import {FollowUpType} from "../FollowUpList";
import {sendEvent} from "../../../../service/tracker";

export const useFollowUpNewCalls = () => {
    const { setLoading, userData } = useContext(MainContextProvider)
    const [upcomingCalls, setUpcomingCalls] = useState<FollowUpType[]>([])
    const [availableCalls, setAvailableCalls] = useState<CallListTypes[]>([])
    const [loader, setLoader] = useState(false);
    const openedCallId = useRef<number | null>()
    const itemsRef = useRef<CallListTypes[]>([])


    const getData = async () => {
        setLoader(true);
        try {
            const { status: upcomingCallsStatus, data: upcomingCallsData } = await customAxios.get(`/call/list?status_list[]=0&company_id=${userData?.companies[0].id}`)
            const { status, data } = await customAxios.get(`/call/list/available`)
            if (status === 200 && upcomingCallsStatus === 200) {
                setAvailableCalls(data)
                setUpcomingCalls(upcomingCallsData.calls)
                if (userData?.status.type_code === 100) {
                    sendEvent("upcoming_and_remaining_calls_view", {
                        user_id: userData?.user_id,
                        timestamp: (+(new Date()) / 1000).toFixed(),
                        company_id: userData?.companies[0]?.id
                    })
                }
            }
        } catch (e) {}
        setLoader(false);
    }

    useEffect(() => {
        document.title = "Upcoming & Remaining Calls"
        getData()
        setLoading(false)
    }, []);

    useEffect(() => {
        const handleEventScheduled = (event: any) => {
            if (event.data.event === "calendly.event_scheduled") {
                const copyAvailableCalls = itemsRef.current
                const findIndexEl = copyAvailableCalls.findIndex(call => +call.call_id === openedCallId.current)
                if (findIndexEl >= 0) {
                    copyAvailableCalls[findIndexEl].call_type.link = ""
                }
                console.log(copyAvailableCalls[findIndexEl], copyAvailableCalls)
                setAvailableCalls([...copyAvailableCalls])
            }
        };

        window.addEventListener("message", handleEventScheduled);

        return () => {
            window.removeEventListener("message", handleEventScheduled);
        };
    }, []);

    const bookCall = (link: string, call_id: string) => {
        openedCallId.current = +call_id
        itemsRef.current = availableCalls
        openPopupWidget({ url: `${link}?utm_source=${call_id}` });
    }

    return {
        upcomingCalls,
        availableCalls,
        bookCall,
        loader
    }
}