import React, {useContext, useEffect, useRef, useState} from "react";
import {useHistory} from "react-router-dom";
import './CreateOffer.scss'
import {CreateOfferSelectContainer} from "./CreateOfferSelectContainer";
import {CreateOfferSecondStep} from "./CreateOfferSecondStep";
import {getAllRequestsService, createOfferFromRequestService} from "../../../service/client/legalRequest";
import {MainContextProvider} from "../../../context/MainContext";
import {ModalLoader} from "../../../components/other/ModalLoader/ModalLoader";
import {BusinessGoalType} from "../../BusinessGoals/BusinessGoal.type";

export type LegalRequestItemType = {
    company_name: string,
    created_at: string
    id: number
    title: string
    description: string
}

export const CreateOffer: React.FC = () => {
    const { modalData, setModalType, setModalData } = useContext(MainContextProvider)
    const [loading, setLoading] = useState(true)
    const [needs, setNeeds] = useState<LegalRequestItemType[]>([])
    const [choose, setChoose] = useState('')
    const [step, setStep] = useState<1 | 2>(1)
    const history = useHistory()

    const getAllRequests = async () => {
        setLoading(true)
        try {
            const { status, data } = await getAllRequestsService()
            if(status === 200 && data) {
                setNeeds([
                    {id: '', title: '-- No Request --'},
                    ...data.map((item: any) => ({
                        id: item.id,
                        title: item.title,
                        description: item.description,
                        company_name: item.company_name,
                        created_at: item.created_at
                    }))
                ])
            }
        } catch (e) { }

        setLoading(false)
    }

    const createOfferFromFirstStep = async () => {
        if(choose) {
            setLoading(true)
            const { status, data } = await createOfferFromRequestService(+choose)
            if(status === 200) {
                history.push(`/offer/${data.id}?edit=true`)
                setModalType('')
                setModalData(null)
            }
            setLoading(false)
        } else {
            setStep(2)
        }
    }

    useEffect(() => {
        if(modalData && modalData.step) {
            setStep(modalData.step)
            setLoading(false)
        } else {
            getAllRequests().then()
        }

    }, [modalData])

    return (
        <div className={'create_manager_offer__container'} style={{ position: 'relative' }}>
            {loading && <ModalLoader />}
            {!modalData?.id && <p className={'create_manager_offer__description'}>
                Create an offer based on a client request or use a blank offer to create a custom offer.
            </p>}
            {step === 1 && <CreateOfferSelectContainer
                setStep={createOfferFromFirstStep}
                setChoose={(id) => setChoose(id)}
                needs={needs}
                choose={choose}
            />}
            {step === 2 && <CreateOfferSecondStep needs={needs} choose={choose} />}
        </div>
    )
}