import React, {useContext, useState} from "react";
import './offerHeading.scss'
import {useTranslation} from "react-i18next";
import {MainContextProvider} from "../../../../context/MainContext";
import clientIcon from '../../../../assets/images/clickIcon.svg'
import readMoreIcon from "../../../../assets/images/icons/readMoreBlue.svg"
import { Link } from "react-router-dom"

import {dateFormatter} from "../../../../helpers/dateFormatter";
import {BusinessGoalType} from "../../../../containers/BusinessGoals/BusinessGoal.type";

type Props = {
    heading: string
    step?: string
    text: string
    company_id?: number
    isNextStep?: boolean
    isEdit?: boolean
    id?: number
    data: any
    isUpdateLegalRequest?: boolean
    isHideContent?: boolean
    setIsHideContent?: (key: boolean) => void
    business_goal?: BusinessGoalType
}

export const OfferHeading: React.FC<Props> = ({
        heading,
        text,
        data,
        isHideContent = true,
        setIsHideContent,
        business_goal
    }) => {
    const {userData, setModalData, setModalType} = useContext(MainContextProvider)
    const {t} = useTranslation()

    const renderTextContent = () => {
        const cleanText = (text || "").replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '')
        if (isHideContent) {
            return (
                <>
                    <div>
                        <div>
                            {`${cleanText.slice(0, 180)}...`}
                        </div>

                        <div
                            onClick={() => setIsHideContent && setIsHideContent(false)}
                            className="offerHeading__show-more"
                        >
                            Show more <img src={readMoreIcon} alt=""/>
                        </div>
                    </div>
                </>
            );
        }
        return <p dangerouslySetInnerHTML={{__html: text}}/>;
    };

    const renderClients = () => {
        if (userData?.status.type_code !== 100 && Array.isArray(data.client)) {
            return data.client.map((item: any) => (
                <div key={item.id} className="offerHeading__client">
                    <img
                        onClick={() => {
                            setModalData(item.id);
                            setModalType('client_profile_modal');
                        }}
                        src={item.path || item.avatar_path}
                        alt=""
                        style={{width: '36px', height: '36px', borderRadius: '10px'}}
                    />
                    <div>
                        <p>{item.fullname}</p>
                        <span>
                            <em>{item.title}</em>
                            {item.company?.name}
                        </span>
                    </div>
                </div>
            ));
        }
        return null;
    };

    return (
        <div className="offerHeading">
            <div className="offerHeader__date" style={{marginBottom: "15px"}}>
                <b>Project:</b>
                {business_goal ? (
                    <Link style={{textTransform: 'initial', fontSize: "13px" }} to={`/legal-projects/${business_goal.id}`}>
                        &nbsp;{business_goal.title}
                    </Link>
                ) : (
                    <span>&nbsp;No Linked Project</span>
                )}
            </div>
            {window.innerWidth > 500 && (
                <div className="offerHeader__date">
                    <b>UPDATED ON: </b>
                    <span>{data.updated_at && dateFormatter(data.updated_at + '')}</span>
                </div>
            )}
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <h2 className="offerHeading__heading">{heading}</h2>
            </div>
            <div className="offerHeading__text task-body__description task-body__description-block">
                {renderTextContent()}
            </div>
            {!isHideContent && <div style={{display: 'flex', flexWrap: 'wrap'}}>{renderClients()}</div>}
        </div>
    );
};