import React from "react";
import {FollowUpTabs} from "../../components/FollowUpTabs/FollowUpTabs";
import {FollowUpListFilter} from "../FollowUpListFilter";
import {CustomLoader} from "../../../../components/UI/CustomLoader/CustomLoader";
import {FollowUpListItem} from "../FollowUpListItem";
import {followUpColumnsWidth} from "../FollowUpList";
import Pagination from "../../../../components/other/Pagination";
import {useManagerFollowUpList} from "./useManagerFollowUpList";
import {ManagerFollowUpListItem} from "./ManagerFollowUpListItem";
import {toastifySuccess} from "../../../../service/toastify/toastify";

export const ManagerUpcomingCall = () => {
    const {
        items,
        loading,
        updateParam,
        searchParams,
        count,
        modalConfig,
        setModalConfig,
        getData,
        isClient,
        clientCallLink,
        userData,
        companies,
        isOpenDestroyModal,
        setIsOpenDestroyModal,
        destroyItem,
        creditCount,
        makeConducted
    } = useManagerFollowUpList()

    if (companies.length === 0) return <></>

    return (
        <div className={"follow-up-list"} style={{ paddingBottom: "50px" }}>
            <div className="follow-up-list__content">
                <h1>Calls & Follow-ups</h1>
                <FollowUpTabs/>
                <h2>Upcoming</h2>
                <FollowUpListFilter isClient={isClient} />
                {loading && <CustomLoader />}
                {!loading && items.length > 0 && <div className="follow-up-list__table">
                    <div className="follow-up-list__table-tr">
                        <div style={followUpColumnsWidth[0]} className="follow-up-list__table-th">Project</div>
                        <div style={followUpColumnsWidth[1]} className="follow-up-list__table-th">Type</div>
                        <div style={followUpColumnsWidth[2]} className="follow-up-list__table-th">Date</div>
                        <div style={followUpColumnsWidth[3]} className="follow-up-list__table-th"></div>
                    </div>
                    {
                        (items || []).map(item => <ManagerFollowUpListItem
                            isClient={isClient}
                            key={item.id}
                            item={item}
                            toggleButton={(key) => {
                                if (key === "copy") {
                                    navigator.clipboard.writeText(item.link)
                                    toastifySuccess("Text has been copied to clipboard")
                                } else if (key === "make_contacted") {
                                    makeConducted(item.id + "")
                                }
                            }}
                        />)
                    }
                    <div className={"flex justifyCenter"}>
                        {count > 10 && <Pagination
                            currentPage={Number(searchParams.get("page")) || 1}
                            pagesAmount={Math.ceil(Number(count) / 10)}
                            setCurrentPage={page => updateParam("page", page + "")}
                        />}
                    </div>
                </div>}
                {
                    items.length === 0 && <p style={{ padding: "50px", textAlign: "center" }}>
                        Nothing to show
                    </p>
                }
            </div>
        </div>
    )
}