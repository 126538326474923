import {useHistory, useLocation} from "react-router-dom";
import "./follow-up-tabs.scss"
import {useContext} from "react";
import {MainContextProvider} from "../../../../context/MainContext";

export const FollowUpTabs = () => {
    const { userData } = useContext(MainContextProvider)
    const history = useHistory();
    const location = useLocation();

    let tabs: { value: string; label: string; }[] = []

    if (!userData) return <></>
    if (userData.status.type_code === 100) {
        tabs = [
            { value: "/book-new-calls", label: "Book New Calls" },
            { value: "/upcoming-calls", label: "Upcoming & Remaining Calls" },
            { value: "/follow-ups", label: "Call History & Follow-Ups" },
        ]
    }

    if (userData.status.type_code === 300 || userData.status.type_code === 400) {
        tabs = [
            { value: "/upcoming-calls", label: "New Calls" },
            { value: "/follow-ups", label: "Follow-ups" },
        ]
    }

    return (
        <div className={"follow-up-tabs"}>
            {
                (tabs || []).map((tab, index) => (
                    <div
                        onClick={() => history.push(tab.value)}
                        className={`${location.pathname === tab.value && 'follow-up-tabs__active-item'} follow-up-tabs__item`}>
                        <span>{tab.label}</span>
                    </div>
                ))
            }
        </div>
    )
}