import {useHistory} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {CompanyType} from "../../../types/company.type";
import customAxios from "../../../service/api/interceptors";
import moment from "moment";
import Select from "../../../components/UI/Select/Select";
import {PlatformDateRangePicker} from "../../../components/UI/DateRangePicker/DateRangePicker";
import {dateMonthAgo, followUpCallTypesArray} from "./FollowUpList";
import {MultiSelect} from "../../../components/UI/MultiSelect/MultiSelect";

export const FollowUpListFilter: React.FC<{ isClient: boolean }> = ({ isClient }) => {
    const history = useHistory()
    const searchParams = new URLSearchParams(window.location.search);
    const [loading, setLoading] = useState(false)
    const [companies, setCompanies] = useState<CompanyType[]>([])
    const [types, setTypes] = useState<{ id: number, title: string }[]>([])

    const updateParam = (paramName: string, newValue: string) => {
        /*if ((paramName === "start_date" && newValue === "") || (paramName === "end_date" && newValue === "")) {
            searchParams.delete("start_date")
            searchParams.delete("end_date")
        }*/

        if (searchParams.has(paramName)) {
            searchParams.set(paramName, newValue);
        } else {
            searchParams.append(paramName, newValue);
        }
        searchParams.set("page", '1')
        history.push(`${window.location.pathname}?${searchParams.toString()}`);
    }

    const getData = async () => {
        setLoading(true)
        try {
            const { status: companyStatus, data: companyData } =
                await customAxios.get(`/company/company/get-companies`)

            const { data: typeData } = await customAxios.get(`/call/types`)

            if (companyStatus === 200) {
                setCompanies(companyData)
                setTypes(typeData)
            }
        } catch (e) {}
        setLoading(false)
    }

    useEffect(() => {
        document.title = "Calls & Follow-ups"
        getData().then()
    }, [])

    const updateDatePickerValue = (range: { startDate: Date | undefined, endDate: Date | undefined }) => {
        if (range.startDate === undefined) {
            searchParams.delete("start_date")
            searchParams.delete("end_date")
            history.push(`${window.location.pathname}?${searchParams.toString()}`);
            return
        }

        if (range.startDate && (moment(searchParams.get("start_date") || new Date()).format("DD-MM-YYYY") !== moment(range.startDate).format("DD-MM-YYYY"))) {
            updateParam("start_date", String(+range.startDate))
        }

        if (range.endDate && (moment(searchParams.get("end_date") || new Date()).format("DD-MM-YYYY") !== moment(range.endDate).format("DD-MM-YYYY"))) {
            updateParam("end_date", String(+range.endDate))
        }
    }

    if (loading) return <></>


    return (
        <div className={"follow-up-list-filter flex"}>
            {!isClient && <Select
                className={"follow-up-list__filter-select mr10"}
                topLabel={"Company"}
                value={searchParams.get("company_id") || ""}
                options={[
                    {value: "", label: "Not selected"},
                    ...companies.map(el => ({value: el.id, label: el.name}))
                ]}
                onChange={e => updateParam("company_id", e.value)}
            />}
            <MultiSelect
                className={"follow-up-list__filter-select mr10"}
                label={"Call type"}
                options={(types || []).map(el => ({ value: el.id + "", label: el.title }))}
                value={searchParams.get("call_type") || ""}
                onChange={e => updateParam("call_type", e)}
            />
            <PlatformDateRangePicker
                className={"mr10"}
                label={"Date"}
                startDate={searchParams.has("start_date") ? new Date(Number(searchParams.get("start_date"))) : undefined}
                endDate={searchParams.has("end_date") ? new Date(Number(searchParams.get("end_date"))) : undefined}
                /*// @ts-ignore*/
                onChange={updateDatePickerValue}
            />
            <Select
                className={"follow-up-list__filter-select"}
                topLabel={"Sort by"}
                value={searchParams.get("sort_by") || "DESC"}
                options={[
                    { value: "DESC", label: "Recent to Oldest" },
                    { value: "ASC", label: "Oldest to Recent" },
                ]}
                onChange={e => updateParam("sort_by", e.value)}
            />
        </div>
    )
}