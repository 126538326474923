import React, {useContext, useEffect, useState} from "react";
import ModalContainer from "../../../../components/ModalContainer";
import {useByNewCallModal} from "./useByNewCallModal";
import "./by-new-call-modal.scss"
import {ChooseCalls} from "./ChooseCalls";
import {ThanksComponent} from "./ThanksComponent";
import {BookNewFollowUpTypesItemType} from "../../BookNewFollowUp/useBookNewFollowUp";
import {MainContextProvider} from "../../../../context/MainContext";
import customAxios from "../../../../service/api/interceptors";
import {useHistory} from "react-router-dom";
import {toastifySuccess} from "../../../../service/toastify/toastify";
import { openPopupWidget } from "react-calendly";
import {ModalLoader} from "../../../../components/other/ModalLoader/ModalLoader";

type Props = {
    isOpen: boolean
    close: () => void
    item: BookNewFollowUpTypesItemType | null
}

export const ByNewCallModal: React.FC<Props> = ({ isOpen, close, item }) => {
    const { creditCount, userData, getCreditCount } = useContext(MainContextProvider);
    const [count, setCount] = useState(1);
    const [isShowThankModal, setIsShowThankModal] = useState(false);
    const history = useHistory();
    const [bookData, setBookData] = useState<{ id: number, call_type: { link: string } } | null>(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!isOpen) {
            setCount(1)
            setIsShowThankModal(false)
        }
    }, [isOpen]);

    const buyCredits = async () => {
        setLoading(true);
        try {
            const { status, data } = await customAxios.post(`/call/purchase`, {
                count, type: item?.id, company_id: userData?.companies[0].id
            })
            if (status === 200) {
                setBookData(data[0])
                setIsShowThankModal(true)
                getCreditCount()
            }
        } catch (e) {}
        setLoading(false);
    }

    const bookCall = async () => {
        console.log(`${bookData?.call_type?.link}?utm_source=${bookData?.id}`);
        if (bookData?.call_type?.link) openPopupWidget({ url: `${bookData?.call_type?.link}?utm_source=${bookData.id}` });
        // https://calendly.com/webdevmail1994/30min
    }

    useEffect(() => {
        const handleEventScheduled = (event: any) => {
            if (event.data.event === "calendly.event_scheduled") {
                history.push(`/upcoming-calls`)
            }
        };

        window.addEventListener("message", handleEventScheduled);

        return () => {
            window.removeEventListener("message", handleEventScheduled);
        };
    }, []);

    if (loading) {
        return (
            <ModalContainer isOpen={isOpen} close={close} heading={"NEW CALL"}>
                <div className="relative">
                    <ModalLoader />
                </div>
            </ModalContainer>
        )
    }

    return (
        <div className={"by-new-call-modal"}>
            <ModalContainer isOpen={isOpen} close={close} heading={"NEW CALL"}>
                <div>
                    {(item && !isShowThankModal) && <ChooseCalls
                        count={count}
                        setCount={setCount}
                        item={item}
                        totalCredits={creditCount.total_count}
                        onClick={buyCredits}
                    />}
                    {isShowThankModal && <ThanksComponent bookCall={bookCall} />}
                </div>
            </ModalContainer>
            {/*  https://calendly.com/webdevmail1994/30min  */}
        </div>
    )
}