import "./no-calls-screen.scss"

export const NoUpcomingCalls = () => {
    return (
        <div className={"no-calls-screen no-calls-screen-upcoming"}>
            <p>
                you DO NOT HAVE ANY UPCOMING CALLS YET.<br />
            </p>
        </div>
    )
}