import React, { useContext, useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { withRouter } from 'react-router'
import { useTranslation } from 'react-i18next'
import profileIcon from '../../../assets/images/client.png'
import OnBoardingIcon from '../../../assets/images/leftNav/onBoarding.png'
import settingsIcon from '../../../assets/images/nav/settings.svg'
import clientIcon from '../../../assets/images/icon-clients.png'
import projectIcon from '../../../assets/images/icon-projects.png'
import taskIcon from '../../../assets/images/icon-tasks.png'
import envelopeImg from '../../../assets/images/envelope-support.svg'
import reportIcon from '../../../assets/images/copy.svg'
import invoiceIcon from '../../../assets/images/invoicesIcon.svg'
import docsIconT from '../../../assets/images/nav/docs.svg'
import arrowToBottom from '../../../assets/images/arrow-to-bottom-blue.svg'
import { ReactComponent as ClientDataRoomIcon } from '../../../assets/images/leftNav/clientDataRoom.svg'
import { ReactComponent as ClientDashboardIcon } from '../../../assets/images/leftNav/clientDashboard.svg'
import useIsUserTypeClient from '../../../hooks/useIsUserTypeClient'
import { LeftNavType } from './leftNav.type'
import { MainContextProvider } from '../../../context/MainContext'
import AvatarImg from '../../../components/other/AvatarImg'
import {NavReport} from "./NavReport";
import { ReactComponent as boardIcon } from '../../../assets/images/leftNav/board.svg'
import { ReactComponent as archiveIcon } from '../../../assets/images/leftNav/archive.svg'
import { ReactComponent as reportsIcon } from '../../../assets/images/leftNav/archive.svg'
import { ReactComponent as billingIcon } from '../../../assets/images/invoicesIcon.svg'
import { ReactComponent as docsIcon } from '../../../assets/images/nav/docs.svg'
import { ReactComponent as bgIcon } from '../../../assets/images/icons/bussinesGoals.svg'
import { ReactComponent as legalRoadmap } from '../../../assets/images/leftNav/legalRoadmap.svg'
import { ReactComponent as headerCalls } from '../../../assets/images/headerCalls.svg'
import bgIconSvg from '../../../assets/images/icons/bussinesGoals.svg'
import faqIcon from '../../../assets/images/faq-person-2.png'
import faqLinkIcon from '../../../assets/images/faq-link.svg'
import haveAQuestionsIcon from '../../../assets/images/have-a-questions.svg'
import {useIntercom} from "react-use-intercom";
import {HaveQuestions} from "./ClientLayout/HaveQuestions/HaveQuestions";
import customAxios from "../../../service/api/interceptors";

/* const clientNav: LeftNavType[] = [
    { SvgComponent: ClientDashboardIcon, link: "dashboard", title: "Dashboard", counter: 0},
#    { SvgComponent: ClientDataRoomIcon, link: "dataRoom", title: "Data room", counter: 0},
]; */

const LeftNav: React.FC<any> = ({ history }) => {
  const { userData, setModalType, userOnBoarding, unPainInvoice, headerTimer, setCurrentTimer } = useContext(MainContextProvider)
  const { isUserTypeClient } = useIsUserTypeClient()
  const { t } = useTranslation()
  const { boot, shutdown, hide, show, update, showMessages } = useIntercom();

  const managerNav: LeftNavType[] = [
    // {
    //   SvgComponent: ClientDashboardIcon, link: 'dashboard', title: 'Dashboard', counter: 0,
    // },
    {
      SvgComponent: boardIcon, link: 'dashboard', title: 'Tasks', counter: 0,
    },
    {
      SvgComponent: archiveIcon, link: 'archive', title: 'Archive', counter: 0,
    },
    {
      SvgComponent: legalRoadmap, link: 'legal-projects', title: 'Projects', counter: 0,
    },
    {
      SvgComponent: headerCalls, link: 'upcoming-calls', title: 'CALLS', counter: 0,
    },
  ]

  const clientNav: LeftNavType[] = [
    {
      SvgComponent: boardIcon, link: 'dashboard', title: 'Tasks', counter: 0,
    },
    {
      SvgComponent: legalRoadmap, link: 'legal-projects', title: 'Projects', counter: 0,
    },
    {
      SvgComponent: headerCalls, link: 'book-new-calls', title: 'CALLS', counter: 0,
    },
    {
      SvgComponent: reportsIcon, link: 'reports', title: 'Reports', counter: 0,
    },
    {
      SvgComponent: docsIcon, link: 'document', title: 'Documents', counter: 0,
    },
  ]
  const lawyerNav: LeftNavType[] = [
    {
      SvgComponent: boardIcon, link: 'dashboard', title: 'Tasks', counter: 0,
    },
    {
      SvgComponent: billingIcon, link: 'settings/history', title: 'Billing', counter: 0,
    },
  ]

  const SvgBillingIcon = () => (billingIcon)

  const onBoardingNav: LeftNavType[] = [
    {
      icon: 'OnBoardingIcon', link: 'onboarding', title: 'Onboarding', counter: 0,
    },
  ]

  const BillingIcon: LeftNavType[] = [
    {
      SvgComponent: billingIcon, link: '/settings/history', title: 'Billing', counter: 0,
    },
  ]

  const images:any = {
    clientIcon,
    projectIcon,
    taskIcon,
    OnBoardingIcon
  }

  const stopTimer = async () => {
    try {
      const { data: tags } = await customAxios.get(`/timely/tags`)
      const { status, data } = await customAxios.put(`/timely/event/${headerTimer?.timer_id}/stop-timer`, {
        tag_id: tags[0].id, note: headerTimer?.type

      })
      if (status === 200) {
        // @ts-ignore
        setHeaderTimer(null)
        setCurrentTimer('')
      }
    } catch (e) {}
  }

  const nav = useMemo(() =>
      (userData?.status.type_code === 100) ? clientNav : (userData?.status.type_code === 200) ? lawyerNav : managerNav, [isUserTypeClient, userData, unPainInvoice])

  const logout = async () => {
    if (headerTimer?.created_at) {
      await stopTimer()
    }
    localStorage.clear()
    window.location.reload()
  }

  return (
    <div className={'layout__sidebar'}>
      <ul className={'layout__sidebar-nav'}>
        {
          (!userOnBoarding && userData?.status.type_code === 100 ? onBoardingNav : nav).map(({
            icon, link, title, counter, SvgComponent,
          }) => {
            if (userData?.status.type_code === 100 && link === 'legal-projects' && !userData.is_backlog_business_goals) {
              return <></>
            } else {
              return (
                  <li key={link}>
                    <NavLink className={'layout__sidebar-nav-link'} activeClassName={'layout__sidebar-nav-link_active'} to={`/${link}`}>
                      { icon && <img className={'layout__sidebar-nav-icon'} src={images[icon]} alt={''} /> }
                      { SvgComponent && <span className={'layout__sidebar-nav-icon'}><SvgComponent /></span> }
                      {title}
                      {counter > 0
                          && <span className={'layout__sidebar-counter'}>{counter}</span>}
                    </NavLink>
                  </li>
              )
            }
          })
        }
        {userData?.status.type_code === 100 && <li>
          {
            BillingIcon.map(({ SvgComponent, link, title }) => {
              return <NavLink className={'layout__sidebar-nav-link'} activeClassName={'layout__sidebar-nav-link_active'} to={link}>
                { SvgComponent && <span className={'layout__sidebar-nav-icon'}><SvgComponent /></span> }
                {title}
                {unPainInvoice > 0 && <span className={'layout__sidebar-counter'}>{unPainInvoice}</span>}
              </NavLink>
            })
          }

        </li>}
        {/*{userData?.status.type_code === 100 && <li>
          <a className={'layout__sidebar-nav-link '} href={`https://hub.legalnodes.co/`}>
            <img className={'layout__sidebar-nav-icon'} src={docsIconT} alt={''} />
            Data Hub
          </a>
        </li>}*/}
        {/*{userData?.status.type_code === 100 && userData.is_backlog_business_goals && <li>
          <NavLink className={'layout__sidebar-nav-link'} activeClassName={'layout__sidebar-nav-link_active'} to={`/business-goals`}>
            <img className={'layout__sidebar-nav-icon'} src={bgIconSvg} alt={''} />
            Legal Roadmap
          </NavLink>
        </li>}*/}
      </ul>

      <div className={'layout__sidebar-profile'}>
        <div onClick={() => history.push('/settings/account')} className={'layout__sidebar-profile_container pointer'}>
          <img style={{ width: '30px', height: '30px' }} className={'layout__sidebar-profile_photo'} src={userData?.avatar_url} alt={''} />
          <div className={'layout__sidebar-profile_desc'}>
            <span className={'layout__sidebar-profile_name'}>
              {(userData?.name || "").split(" ").length >= 1 ? userData?.name.split(" ")[0].substring(0, 1) : ""}
              {(userData?.name || "").split(" ").length >= 2 ? userData?.name.split(" ")[1].substring(0, 1) : ""}
              {/*{userData && userData.name
                                && `${userData.name.length > 15 ? `${userData.name.substring(0, 15)}...` : userData.name}`}*/}
            </span>
          </div>
        </div>
        <em onClick={logout} className={'layout__sidebar-profile_logout'}>{'Log out'}<img src={arrowToBottom} alt="" /></em>
        {/*<img onClick={() => history.push('/settings/account')} className={'layout__sidebar-profile_show'} src={settingsIcon} alt={''} />*/}
      </div>

      {/*{(userData?.status.type_code === 300 || userData?.status.type_code === 400) && <div onClick={() => showMessages()} style={{ cursor: 'pointer' }} className="sidebar__faq">
        <h4 className={'sidebar__faq-heading'}>Got a &nbsp;&nbsp;&nbsp;question?</h4>
        <img className={'sidebar__faq-person'} src={faqIcon} alt=""/>
        <a style={{ textDecoration: 'none' }} className={'sidebar__faq-link'}>
          <span style={{ marginRight: '25px' }} className={'sidebar__faq-text'}>Contact support</span>
          <img className={'sidebar__faq-link_img'} src={faqLinkIcon} alt=""/>
        </a>
      </div>}*/}

      {/*{userData?.status.type_code === 100 && <HaveQuestions
          showMessages={showMessages}
      />}*/}
    </div>
  )
}

export default withRouter(LeftNav)
