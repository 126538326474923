import React, {useEffect, useRef, useState} from "react";
import {CallListTypes, columnsAvailableWidth} from "../FollowUpList/FollowUpNewCalls/FollowUpNewCalls";
import {useOnClickOutside} from "../../../hooks/useOnClickOutside";
import toggleImage from "../icons/toggle.svg";
import {CSSTransition} from "react-transition-group";
import {openPopupWidget} from "react-calendly";

export const FollowUpNewCallAvailableItem: React.FC<{ item: CallListTypes, bookCall: () => void }> = ({ item, bookCall }) => {
    if (!item) return <></>

    return (
        <div className="follow-up-list-item">
            <div className="follow-up-list-item-td" style={columnsAvailableWidth[0]}>
                <b>{item.call_type?.title}</b>
                <p>{item.call_type?.subtitle}</p>
            </div>
            <div className="follow-up-list-item-td" style={columnsAvailableWidth[1]}>
                <p>{item.call_type?.duration}</p>
            </div>
            <div className="follow-up-list-item-td" style={columnsAvailableWidth[2]}>
                <p>{item.count}</p>
            </div>
            <div className="follow-up-list-item-td flex alignCenter" style={columnsAvailableWidth[3]}>
                <button disabled={!item.call_type.link} onClick={bookCall} className={"btn-white w100 text-uppercase mr10"}>
                    BOOK
                </button>
                {/*<FollowUpItemToggle />*/}
            </div>
        </div>
    )
}

const FollowUpItemToggle: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false)
    const dropdownRef = useRef<HTMLDivElement>(null)
    useOnClickOutside({ ref: dropdownRef, handler: () => setIsOpen(false) })

    return (
        <div ref={dropdownRef} className="follow-up-list-item-toggle">
            <img onClick={() => setIsOpen(!isOpen)} src={toggleImage} alt=""/>
            <CSSTransition in={isOpen} timeout={{ enter: 200, exit: 200 }} classNames={'my-node'} unmountOnExit>
                <div>
                    <div className="follow-up-list-item-toggle-squire" />
                    <div className="follow-up-list-item-toggle-dropdown">
                        <div>Edit</div>
                    </div>
                </div>
            </CSSTransition>
        </div>
    )
}