import {useContext, useEffect, useState} from "react";
import {MainContextProvider} from "../../../context/MainContext";
import customAxios from "../../../service/api/interceptors";
import {sendEvent} from "../../../service/tracker";
import {useHistory} from "react-router-dom";

export type BookNewFollowUpTypesItemType = {
    id: number,
    title: string,
    subtitle: string,
    credit_price: string,
    duration: string,
    description: string,
    link: string
    image: string
}

type isOpenByNewCallModalType = {
    isOpen: boolean,
    item: BookNewFollowUpTypesItemType | null
}

export const useBookNewFollowUp = () => {
    const { setLoading, userData, creditCount } = useContext(MainContextProvider)
    const history = useHistory();
    const [items, setItems] = useState<BookNewFollowUpTypesItemType[]>([])
    const [loader, setLoader] = useState<boolean>(false)
    const [isOpenByNewCallModal, setIsOpenByNewCallModal] = useState<isOpenByNewCallModalType>({
        isOpen: false,
        item: null
    })

    const getData = async () => {
        setLoader(true)
        try {
            const { status, data } = await customAxios.get(`/call/types`)
            if (status === 200) {
                const arr: any[] | ((prevState: BookNewFollowUpTypesItemType[]) => BookNewFollowUpTypesItemType[]) = []
                Object.keys(data).forEach((key: string) => {
                    arr.push(data[key])
                })
                setItems(arr)
                if (userData?.status.type_code === 100) {
                    sendEvent("expert_calls_hub_view", {
                        user_id: userData?.user_id,
                        timestamp: (+(new Date()) / 1000).toFixed(),
                        company_id: userData?.companies[0]?.id
                    })
                }
            }
        } catch (e) {}
        setLoader(false)
        setLoading(false)
    }

    useEffect(() => {
        if (creditCount && creditCount.isLoad && !creditCount.isCreditSubscription) {
            history.push("/follow-ups")
        } else {
            getData()
        }
        document.title = "Book New Calls"
    }, [creditCount, userData]);

    return {
        items,
        isOpenByNewCallModal,
        setIsOpenByNewCallModal,
        loader
    }
}