import React, {useContext, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import customAxios from "../../../service/api/interceptors";
import {BusinessGoalType} from "../BusinessGoal.type";
import BreadcrumbsToIcon from "../icons/breadcrumbs.svg"
import DropDownIcon from "../icons/dropdown.svg"
import {MainContextProvider} from "../../../context/MainContext";
import {CustomLoader} from "../../../components/UI/CustomLoader/CustomLoader";
import "./business_goal_view.scss"
import {ProjectSuggestions} from "./components/ProjectSuggestions";
import {CardDropdown} from "../../../components/other/CardDropdown/CardDropdown";
import {CreateBusinessGoal, UpdateBusinessGoal} from "../modals/CreateBusinessGoal/CreateBusinessGoal";
import moment from "moment";

export const BusinessGoalView: React.FC = () => {
    const { setLoading, userData, setCurrentTimer } = useContext(MainContextProvider)
    const { id }: { id: string } = useParams()
    const [localLoading, setLocalLoading] = useState(true)
    const [itemData, setItemData] = useState<BusinessGoalType>({} as BusinessGoalType)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const history = useHistory()

    const getItem = async () => {
        setLocalLoading(true)
        try {
            const { status, data } = await customAxios.get(`/business-goal/${id}`)
            if (status === 200) {
                setItemData(data)
            }
        } catch (e) {}
        setLocalLoading(false)
    }

    const moveStage = async (stage: number) => {
        setLocalLoading(true)
        try {
            const { status, data } = await customAxios.put(`/business-goal/${id}`, { stage })
            if (status === 200) {
                await getItem()
            }
        } catch (e) {}
        setLocalLoading(false)
    }

    useEffect(() => {
        getItem().then()
        setLoading(false)
        document.title = 'Legal Project'
        setCurrentTimer('redirect')
    }, [])

    const getPriority = (priority: number) => {
        switch (priority) {
            case 1: return { value: 1, label: 'Low', color: '#000' }
            case 2: return { value: 2, label: 'Medium', color: '#FF9E45' }
            case 3: return { value: 3, label: 'High', color: '#F00' }
            default: return { value: '', label: 'Not Selected', color: '#ad5700' }
        }
    }

    const deleteItem = async () => {
        if (!window.confirm('Are you sure?')) return

        setLocalLoading(true)
        try {
            const { status, data } = await customAxios.delete(`/business-goal/${id}`)
            if (status === 200) {
                history.push(`/legal-projects?company=${itemData.company_id}`)
            }
        } catch (e) {}
        setLocalLoading(false)
    }

    if (localLoading) {
        return <CustomLoader />
    }

    return (
        <div className={'business_goal_view'}>
            <div style={{ display: "flex" }}>
                <div style={{ width: "50%" }}>
                    <div className="business_goal_view__breadcrumbs">
                <span
                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                    onClick={() => history.push(`/legal-projects?client=${itemData?.company_id}`)}>{itemData?.company?.name} Legal Project</span>&nbsp;&nbsp;
                        <img src={BreadcrumbsToIcon} alt=""/>&nbsp;&nbsp;
                        <span>{itemData.title}</span>
                    </div>
                    <ProjectSuggestions BusinessGoal={itemData} />
                </div>
                <div style={{ width: "50%", marginLeft: "20px", marginTop: "7px" }}>
                    <div className={'business_goal_view__container'}>
                        {<div className="project_suggestions__client-head-heading" style={{paddingTop: "20px"}}>
                            Project details
                        </div>}
                        <div className="business_goal_view__head">
                            <p className={'business_goal_view__created-date'}>
                                <em>CREATED: <b>{moment(+itemData.created_at * 1000).format('DD/MM/YYYY')}</b></em>
                            </p>
                            <div className={'business_goal_view__switch flex alignCenter'}>
                                {userData?.status.type_code === 300 && <div style={{margin: 0}} className="checkout__checkbox"
                                                                            onClick={() => moveStage(+itemData.stage === 3 ? 2 : 3)}
                                >
                                    <span>Draft</span>
                                    <div
                                        className={`checkout__checkbox__container ${+itemData?.stage === 3 && 'checkout__checkbox__container-active'}`}>
                                        <div className="checkout__checkbox-active"/>
                                    </div>
                                    <span>Published</span>
                                </div>}
                                {userData?.status.type_code === 300 && <CardDropdown
                                    items={[
                                        {label: 'Edit', func: () => setIsModalOpen(true)},
                                        {label: 'Delete', func: () => deleteItem()},
                                    ]}
                                />}
                            </div>
                        </div>
                        <div className="business_goal_view__heading">
                            <h2>{itemData?.title}</h2>
                            <div className="business_goal_view__heading-priority">
                                <span>Priority: <b style={{ color: getPriority(+itemData.priority).color }}>{getPriority(+itemData.priority).label}</b></span>
                            </div>
                        </div>
                        <div className="business_goal_view__description task-body__description task-body__description-block">
                            <p dangerouslySetInnerHTML={{ __html: itemData?.description }} />
                        </div>
                    </div>
                </div>
            </div>

            <UpdateBusinessGoal
                isOpen={isModalOpen}
                updateItems={getItem}
                close={() => setIsModalOpen(false)}
                obj={itemData}
            />
        </div>
    )
}