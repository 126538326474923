import React, {useRef, useState} from "react";
import {useOnClickOutside} from "../../../hooks/useOnClickOutside";
import { CSSTransition } from 'react-transition-group'
import "./multiselect.scss"
import icon from "../../../assets/images/arrow-to-bottom-blue.svg"
import {CustomCheckbox} from "../../other/Registration/CustomCheckbox/CustomCheckbox";

type Props = {
    label?: string
    options: { label: string; value: string }[],
    value: string
    onChange: (e: string) => void
    className?: string
}

export const MultiSelect: React.FC<Props> = ({ label, value, onChange, options, className }) => {
    const [isOpen, setIsOpen] = useState(false)
    const dropdownRef = useRef<HTMLDivElement>(null)
    useOnClickOutside({ ref: dropdownRef, handler: () => setIsOpen(false) })

    const results = value ? value.split(",") : []

    const updateResults = (el: string) => {
        (results.includes(el)) ? onChange(results.filter(result => result !== el).join(",")) : onChange([...results, el].join(","))

    }

    return (
        <div className={`${className || ""} multiselect__main`} ref={dropdownRef}>
            {label && <div className={"multiselect__label"}>{label}</div>}
            <div onClick={() => setIsOpen(!isOpen)} className={"multiselect__input"}>
                {!value ? `All` : `Selected: ${results.length}`} <img src={icon} alt=""/>
            </div>
            <CSSTransition in={isOpen} classNames={'my-node'} timeout={200} unmountOnExit>
                <div className={'dropdown__container'}>
                    {
                        options.map((option) => (
                            <div className={"multiselect__option"} key={option.value}>
                                <CustomCheckbox
                                    checked={results.includes(option.value)}
                                    onChange={() => updateResults(option.value)}
                                    label={option.label}
                                    isStopPropagation={false}
                                />
                            </div>
                        ))
                    }
                </div>
            </CSSTransition>
        </div>
    )
}