import { ModalsType } from './modal_types'
import ExampleModal from '../../containers/Modals/ExampleModal'
import CreateProject from '../../containers/Modals/CreateProject/CreateProject'
import MilestonesModal from '../../containers/Modals/Milestones/MilestonesModal'
import DeliverablesModal from '../../containers/Modals/CreateDeliverables/DeliverablesModal'
import CreateTask from '../../containers/Modals/CreateTask'
import SendSupport from '../../containers/Modals/SendSupport/SendSupport'
import LegalTicket from '../../containers/Modals/LegalTicket/LegalTicket'
import PaymentMethodModal from '../../containers/Modals/PaymentMethodModal'
import {WelcomeOnBoarding} from "../../containers/Modals/OnBoarding/WelcomeOnBoarding/WelcomeOnBoarding";
import {ClientKanbanRequest} from "../../containers/Modals/ClientKanbanRequest/ClientKanbanRequest";
import {CreateLegalRequest} from "../../containers/Modals/CreateLegalRequest/CreateLegalRequest";
import {CreateOffer} from "../../containers/Modals/CreateOffer/CreateOffer";
import {CreateLegalTask} from "../../containers/Modals/CreateLegalTask/CreateLegalTask";
import OfferDeliverablesModal from "../../containers/Modals/OfferDeliverables/OfferDeliverablesModal";
import {ManagerProfile} from "../../containers/Modals/ProfileModal/ManagerProfile";
import {ClientProfile} from "../../containers/Modals/ProfileModal/ClientProfile";
import {AttachClientToManager} from "../../containers/Modals/AttachClientToManager/AttachClientToManager";
import {OfflineAcceptOffer} from "../../containers/Modals/OfflineAcceptOffer/OfflineAcceptOffer";
import {LegalTaskDeliverable} from "../../containers/Modals/LegalTaskDeliverable/LegalTaskDeliverable";
import {DeliverableAccept} from "../../containers/Modals/OfferDeliverables/DeliverableAccept";
import {AllApproveDeliverables} from "../../containers/Modals/OfferDeliverables/AllApproveDeliverables";
import {ConfirmBillingData} from "../../containers/Modals/ConfirmBillingData/ConfirmBillingData";
import {InviteTeamMate} from "../../containers/Modals/InviteTeamMate/InviteTeamMate";
import {FrozenModal} from "../other/FrozenController/FrozenModal";
import {TransitionFromTrial} from "../../containers/Modals/TransitionFromTrial/TransitionFromTrial";
import {UpdateLegalRequest} from "../../containers/Modals/UpdateLegalRequest/UpdateLegalRequest";
import {MonthlyClientReport} from "../../containers/Modals/MonthlyClientReport/MonthlyClientReport";
import {ChoosePaymentPlan} from "../../containers/Modals/ChoosePaymentPlan/ChoosePaymentPlan";
import {ServerReload} from "../other/ServerReload/ServerReload";
import {RedFlags} from "../../containers/Modals/RedFlags/RedFlags";
import {KYCModal} from "../../containers/Modals/KYCModal/KYCModal";
import {ProviderConfirmBillingData} from "../../containers/Modals/ProviderConfirmBillingData/ConfirmBillingData";
import {TermsUsePrivacyPolicy} from "../../containers/Modals/TermsUsePrivacyPolicy/TermsUsePrivacyPolicy";
import {OfferDeliverableModal} from "../../containers/Modals/OfferDeliverableModal/OfferDeliverableModal";
import {CreditDeletedModal} from "../../containers/Modals/Credits/CreditDeletedModal";

const ALL_MODALS:ModalsType[] = [
  {
    type: 'example',
    Component: ExampleModal,
  },
  {
    type: 'milestones-modal',
    Component: MilestonesModal,
    modalProps: {
      modalClass: 'create_milestones',
      heading: 'Add Milestone',
      headingClass: 'create_milestones__heading',
    },
  },
  {
    type: 'deliverables-modal',
    Component: DeliverablesModal,
    modalProps: {
      modalClass: 'create_deliverables',
      heading: 'Add Deliverables',
      headingClass: 'create_deliverables__heading',
    },
  },
  {
    type: 'create_project',
    Component: CreateProject,
    modalProps: {
      modalClass: 'create_project',
      heading: 'NEW PROJECT',
      headingClass: 'create_project__heading',
    },
  },
  {
    type: 'create_task',
    // @ts-ignore
    Component: CreateTask,
    modalProps: {
      modalClass: 'create_task',
      heading: 'NEW TASK',
      headingClass: 'create_task__heading',
    },
  },
  {
    type: 'create_support',
    Component: SendSupport,
    modalProps: {
      modalClass: 'create_task',
      heading: 'Send us a message and we’ll be happy to help',
      headingClass: 'create_task__heading',
    },
  },
  {
    type: 'legal_ticket',
    Component: LegalTicket,
    modalProps: {
      modalClass: 'legal-ticket',
      heading: 'Submit Legal Ticket',
      headingClass: 'legal-ticket__heading',
    },
  },
  {
    type: 'payment_method',
    Component: PaymentMethodModal,
    modalProps: {
      modalClass: 'payment-method-modal',
      heading: 'NEW PAYMENT METHOD',

    },
  },
  {
    type: 'welcome_onBoarding',
    Component: WelcomeOnBoarding,
    modalProps: {
      modalClass: 'welcome_onBoarding'
    }
  },
  {
    type: 'client_kanban_request',
    Component: ClientKanbanRequest,
    modalProps: {
      modalClass: 'client_kanban_request',
      heading: 'New Request'
    }
  },
  {
    type: 'create_legal_request',
    Component: CreateLegalRequest,
    modalProps: {
      modalClass: 'create_legal_request',
      heading: 'Create a new request'
    }
  },
  {
    type: 'update_legal_request',
    Component: UpdateLegalRequest,
    modalProps: {
      modalClass: 'create_legal_request',
      heading: 'Update legal request'
    }
  },
  {
    type: 'create_manager_offer',
    Component: CreateOffer,
    modalProps: {
      modalClass: 'create_manager_offer',
      heading: 'New Offer'
    }
  },
  {
    type: 'create_legal_task',
    Component: CreateLegalTask,
    modalProps: {
      modalClass: 'create_legal_task',
      heading: 'New Task'
    }
  },
  {
    type: 'offer_deliverables_modal',
    Component: OfferDeliverablesModal,
    modalProps: {
      modalClass: 'offer_deliverables_modal',
      heading: 'Edit deliverable'
    }
  },
  {
    type: 'manager_profile_modal',
    Component: ManagerProfile,
    modalProps: {
      modalClass: 'manager_profile',
      heading: 'Profile'
    }
  },
  {
    type: 'client_profile_modal',
    Component: ClientProfile,
    modalProps: {
      modalClass: 'manager_profile',
      heading: 'Client Profile'
    }
  },
  {
    type: 'attach_client_modal',
    Component: AttachClientToManager,
    modalProps: {
      modalClass: 'create_manager_offer',
      heading: 'Add new Client'
    }
  },
  {
    type: 'offline_accept_offer',
    Component: OfflineAcceptOffer,
    modalProps: {
      modalClass: 'create_manager_offer',
      heading: 'Accept Offer'
    }
  },
  {
    type: 'legal_task_deliverable',
    Component: LegalTaskDeliverable,
    modalProps: {
      modalClass: 'create_manager_offer',
      heading: 'Legal task deliverable'
    }
  },
  {
    type: 'deliverable_accept',
    Component: DeliverableAccept,
    modalProps: {
      modalClass: 'create_manager_offer',
      heading: 'Your task is complete!'
    }
  },
  {
    type: 'all_approve_deliverables',
    Component: AllApproveDeliverables,
    modalProps: {
      modalClass: 'all_approve_deliverables create_manager_offer',
      heading: ''
    }
  },
  {
    type: 'confirm_billing_data',
    Component: ConfirmBillingData,
    modalProps: {
      modalClass: 'confirm_billing_data create_manager_offer',
      heading: 'Please confirm your choice',
      hideClose: true
    }
  },
  {
    type: 'confirm_provider_billing_data',
    Component: ProviderConfirmBillingData,
    modalProps: {
      modalClass: 'confirm_billing_data create_manager_offer',
      heading: 'Please confirm your choice',
      hideClose: true
    }
  },
  {
    type: 'frozen_modal',
    Component: FrozenModal,
    modalProps: {
      modalClass: 'frozen_modal',
    }
  },
  {
    type: 'invite_team_mate',
    Component: InviteTeamMate,
    modalProps: {
      modalClass: 'modal_overflow_hide invite_team_mate create_manager_offer',
      heading: 'Invite your teammate'
    }
  },
  {
    type: 'transitionFromTrial',
    Component: TransitionFromTrial,
    modalProps: {
      modalClass: 'create_manager_offer',
      heading: 'Ready to unlock more features?'
    }
  },
  {
    type: 'monthly_client_report',
    Component: MonthlyClientReport,
    modalProps: {
      modalClass: 'monthly_client_report create_manager_offer modal_overflow_hide',
      heading: 'Your VLO\'s Monthly Work Report'
    }
  },
  {
    type: 'choose_payment_plan',
    Component: ChoosePaymentPlan,
    modalProps: {
      modalClass: 'modal_overflow_hide choose_payment_plan',
      heading: ''
    }
  },
  {
    type: 'server_reload',
    Component: ServerReload,
    modalProps: {
      modalClass: 'modal_overflow_hide choose_payment_plan',
      heading: 'Server is restarting, please wait a few minutes'
    }
  },
  {
    type: 'red_flags',
    Component: RedFlags,
    modalProps: {
      modalClass: 'red_flags__container',
      heading: 'Account Health Report'
    }
  },
  {
    type: 'kyc',
    Component: KYCModal,
    modalProps: {
      modalClass: 'kyc_modal',
      heading: ''
    }
  },
  {
    type: 'terms_use_privacy_policy',
    Component: TermsUsePrivacyPolicy,
    modalProps: {
      modalClass: 'modal_overflow_hide terms_use_privacy_policy',
      heading: ''
    }
  },
  {
    type: 'offer_deliverable_modal',
    Component: OfferDeliverableModal,
    modalProps: {
      modalClass: 'modal_overflow_hide offer-deliverable-modal',
      heading: 'Add Deliverable'
    }
  },
  {
    type: 'credit_delete_modal',
    Component: CreditDeletedModal,
    modalProps: {
      modalClass: 'modal_overflow_hide offer-deliverable-modal',
      heading: ''
    }
  },
]

export default ALL_MODALS
