import React, {useContext, useEffect, useState} from "react";
import './checkoutStyle.scss'
import cardIcon from '../../../../assets/images/bankCard.svg'
import bankIcon from '../../../../assets/images/bankIcon.svg'
import downloadIcon from '../../../../assets/images/dowload.svg'
import personalIcon from '../../../../assets/images/personalCreditCart.svg'
import corporateIcon from '../../../../assets/images/corporateCreditCard.svg'
import cryptoIcon from '../../../../assets/images/icons/invoicePaymentCrypto.svg'
import {ProviderBankTransfer} from "./BankTransfer";
import {toastifyError, toastifySuccess} from "../../../../service/toastify/toastify";
import axios from "axios";
import {MainContextProvider} from "../../../../context/MainContext";
import {ProviderPaymentByInvoice} from "./PaymentByInvoice";
import customAxios from "../../../../service/api/interceptors";
import {dateFormatter} from "../../../../helpers/dateFormatter";
import {getBillingClientDataService} from "../../../../service/client/onboardingService";
import {intercomCatchErrorMessage} from "../../../../helpers/intercomCatchErrorMessage";
import {useIntercom} from "react-use-intercom";
import {ProviderPaymentByCrypto} from "./PaymentByCrypto";
import {sendEvent} from "../../../../service/tracker";
import {SelectBillingCountry} from "../../../Modals/SelectBillingCountry/SelectBillingCountry";
import {stripeCountries} from "../../../Modals/ConfirmBillingData/ConfirmBillingData";
import {all_countries} from "../../../../helpers/all_countries";

type PaymentTypesType = 'transfer' | 'card' | 'crypto' | 'test'

export const RadioItem: React.FC<{ active: boolean }> = ({ active }) => {
    return (
        <div className={'checkout__radio flex-center'}>
            {active && <div className="checkout__radio-active" />}
        </div>
    )
}

const MethodDescription: React.FC<{ type: PaymentTypesType, method: string }> = ({ type, method }) => {
    if (type === 'card') {
        return (
            <div className={'checkout__payment-description'}>
                <ul>
                    <li className={'checkout__payment-description-noList'}>If you choose <b>"<div style={{ textTransform: 'uppercase', display: 'inline' }}>{method}</div> Credit Card/Debit Card"</b> as your payment method:</li>
                    <li>We will use this card to take payment.</li>
                    <li>We will use this card to take future payments which will be automatically charged each month.</li>
                    <li>If we are unable to take payment because of a payment error (such as insufficient funds), we will automatically re-attempt to take payment after a few days.</li>
                    <li>All payments made via Personal Credit/Debt Card include a 20% VAT Tax (as per the terms of your VLO Engagement Letter).</li>
                    <li>You will be transferred to a secure page hosted by Stripe to make your payment. Legal Nodes does not store your card payment data. All payment data and transactions are processed by securely by Stripe.</li>
                </ul>
            </div>
        )
    }

    if (type === 'transfer' && method === 'personal') {
        return (
            <div className={'checkout__payment-description'}>
                <ul>
                    <li>If you choose "Bank transfer" as your payment method:</li>
                    <li>This payment, along with all future payments will have to be manually paid via Bank Transfer before the invoice’s Due Date.</li>
                    <li>If applicable, all payments made via Bank Transfer will include VAT Tax according to the tax laws of your country of registration (i.e., 20% VAT for UK, 0% VAT for EU).”</li>
                </ul>
            </div>
        )
    }

    if (type === 'transfer' && method === 'corporate') {
        return (
            <div className={'checkout__payment-description'}>
                <ul>
                    <li>If you choose "Bank transfer from corporate wallet" as your payment method:</li>
                    <li>This payment, along with all future payments will have to be manually paid via Bank transfer before the invoice’s Due Date.</li>
                    <li>If applicable, all payments made via Bank transfer from corporate wallet will include VAT Tax according to the tax laws of your country of registration (i.e., 20% VAT for UK, 0% VAT for EU).”</li>
                </ul>
            </div>
        )
    }

    if (type === 'crypto' && method === 'personal') {
        return (
            <div className={'checkout__payment-description'}>
                <ul>
                    <li>If you choose "Cryptocurrency transfer from personal wallet" as your payment method:</li>
                    <li>This payment, along with all future payments will have to be manually paid via Cryptocurrency transfer before the invoice’s Due Date.</li>
                    <li>All payments made via Cryptocurrency transfer from personal wallet include a 20% VAT Tax (as per the terms of your VLO Engagement Letter).</li>
                </ul>
            </div>
        )
    }

    if (type === 'crypto' && method === 'corporate') {
        return (
            <div className={'checkout__payment-description'}>
                <ul>
                    <li>If you choose "Cryptocurrency transfer from corporate wallet" as your payment method:</li>
                    <li>This payment, along with all future payments will have to be manually paid via Cryptocurrency transfer before the invoice’s Due Date.</li>
                    <li>If applicable, all payments made via Cryptocurrency transfer from corporate wallet will include VAT Tax according to the tax laws of your country of registration (i.e., 20% VAT for UK, 0% VAT for EU).”</li>
                </ul>
            </div>
        )
    }

    return <div />
}

export type InvoiceType = {
    company: {
        id: string
        payment_method: string
    }
    created_at: string
    description: string
    due_date: string
    id: string
    service_list: string
    service: string
    status: "UNPAID" | "PAID"
    sum: string
    invoice_url: string
    xero_invoice_id: boolean
}

export const ProviderCheckout: React.FC<any> = ({ match, history }) => {
    const { params: { id } } = match
    const { setLoading, userData, modalData, setModalData, setModalType } = useContext(MainContextProvider)
    const [paymentType, setPaymentType] = useState<PaymentTypesType>('transfer')
    const [method, setMethod] = useState('personal')
    const [payByInvoice, setPayByInvoice] = useState(false)
    const [payByCrypto, setPayByCrypto] = useState(false)
    const [invoiceInfo, setInvoiceInfo] = useState<InvoiceType>({} as InvoiceType)
    const [validConfirmData, setValidConfirmData] = useState(false)
    const [selectedCountry, setSelectedCountry] = useState('GB')
    const [isOpenSelectCountryModal, setIsOpenSelectCountryModal] = useState(false)
    const { showNewMessages } = useIntercom()

    const trackInvoiceViewEvent = async (invoice: InvoiceType) => {
        await sendEvent('sp_invoice_viewed', {
            amount: invoice.sum,
            provider_id: userData?.user_id,
            timestamp: (+(new Date()) / 1000).toFixed(),
            invoice_id: invoice.id,
            invoice_status: invoice.status === 'PAID' ? '1' : '0'
        })
    }

    const getInvoiceInfo = async () => {
        setLoading(true)
        try {
            const { status, data } = await customAxios.get(`/invoices/${id}`)
            if (status === 200) {
                setInvoiceInfo(data)
                /*trackInvoiceViewEvent(data).then()
                if (+data.company.payment_method === 0) {
                    setMethod('personal'); setPaymentType('card')
                } else if (+data.company.payment_method === 1) {
                    setMethod('corporate'); setPaymentType('card')
                } if (+data.company.payment_method === 2) {
                    setMethod('corporate'); setPaymentType('transfer')
                } if (+data.company.payment_method === 3) {
                    setMethod('personal'); setPaymentType('transfer')
                } if (+data.company.payment_method === 4) {
                    setMethod('corporate'); setPaymentType('crypto')
                } if (+data.company.payment_method === 5) {
                    setMethod('personal'); setPaymentType('crypto')
                }*/
            }
            await getBillingData()
            await trackInvoiceViewEvent(data)
        } catch (e) {
            await showNewMessages(intercomCatchErrorMessage(e))
        }
        setLoading(false)
    }

    const getBillingData = async () => {
        setLoading(true)
        try {
            const { status, data } = await customAxios.get(`/billing-info/provider`)
            if (+data.payment_method === 0) {
                setMethod('personal'); setPaymentType('card')
            } else if (+data.payment_method === 1) {
                setMethod('corporate'); setPaymentType('card')
            } if (+data.payment_method === 2) {
                setMethod('corporate'); setPaymentType('transfer')
            } if (+data.payment_method === 3) {
                setMethod('personal'); setPaymentType('transfer')
            } if (+data.payment_method === 4) {
                setMethod('corporate'); setPaymentType('crypto')
            } if (+data.payment_method === 5) {
                setMethod('personal'); setPaymentType('crypto')
            }
            if (data.stripe_country_code) {
                setSelectedCountry(data.stripe_country_code)
            } else {
                setIsOpenSelectCountryModal(true)
            }
        } catch (e) {}
        setLoading(false)
    }

    useEffect(() => {
        if (modalData === 'billing-confirmed') {
            payment()
            setModalData(null)
        }
    }, [modalData])

    useEffect(() => {
        getInvoiceInfo().then()
        document.title = "Invoice"
    }, [])



    const stripeCheckoutPayment = async () => {
        setLoading(true)
        try {
            const { status, data } = await axios.post(`${process.env.REACT_APP_STRIPE_API_URL}/stripe/create-checkout`, {
                email: userData?.email,
                invoiceName: id,
                type: invoiceInfo.service,
                price: invoiceInfo.sum,
                payment_method: 1,
                user_type: 200
            }, {
                headers: {
                    'Content-Security-Policy': 'upgrade-insecure-requests'
                }
            })
            if (status === 200) {
                setLoading(false)
                window.location.href = data.url;
            }
        } catch (e) {
            await showNewMessages(intercomCatchErrorMessage(e))
        }
        setLoading(false)
    }

    const stripeCheckoutPaymentPersonal = async () => {
        setLoading(true)
        try {
            const { status, data } = await axios.post(`${process.env.REACT_APP_STRIPE_API_URL}/stripe/create-checkout-personal`, {
                email: userData?.email,
                invoiceName: id,
                type: invoiceInfo.service,
                price: invoiceInfo.sum,
                payment_method: 0,
                user_type: 200
            }, {
                headers: {
                    'Content-Security-Policy': 'upgrade-insecure-requests'
                }
            })
            if (status === 200) {
                setLoading(false)
                window.location.href = data.url;
            }
        } catch (e) {
            await showNewMessages(intercomCatchErrorMessage(e))
        }

    }

    const TEST = async () => {
        setLoading(true)
        const { status, data } = await axios.post(`https://node-stripe.legalnodes.co/stripe/create-checkout-test`, {
            email: userData?.email,
            invoiceName: invoiceInfo.id,
            type: invoiceInfo.service,
            price: invoiceInfo.sum,
            payment_method: method === 'personal' ? 0 : 1,
            user_type: 200
        }, {
            headers: {
                'Content-Security-Policy': 'upgrade-insecure-requests'
            }
        })
        if (status === 200) {
            setLoading(false)
            window.location.href = data.url;
        }
    }

    const payment = () => {
        if (paymentType === 'card' && method === 'personal') {
            stripeCheckoutPaymentPersonal().then()
        } else if (paymentType === 'card' && method === 'corporate') {
            stripeCheckoutPayment().then()
        } else if (paymentType === 'test') {
            TEST().then()
        }  else if(paymentType === 'crypto') {
            //setPayByCrypto(true)
            history.push(`/settings/crypto-payment/${invoiceInfo.id}`)
        } else {
            setPayByInvoice(true)
        }
    }

    if (payByInvoice) {
        return <ProviderPaymentByInvoice
            match={match}
            invoiceInfo={invoiceInfo}
            companyId={invoiceInfo.company?.id}
            history={history}
            payment_method_code={method === 'personal' ? 3 : 2}
            paymentType={paymentType}
            method={method}
        />
    }

    if (payByCrypto) {
        return <ProviderPaymentByCrypto
            match={match}
            invoiceInfo={invoiceInfo}
            companyId={invoiceInfo.company?.id}
            history={history}
            payment_method_code={method === 'personal' ? 5 : 4}
            paymentType={paymentType}
            method={method}
        />
    }

    const serviceName = (type: string) => {
        switch (type) {
            case 'retainer': return 'Contract Payment'
            case 'reminder': return 'Payment for Additional VLO Works'
            case 'trial': return 'Payment for Trial Period'
            case 'credit_invoice': return 'Credits Purchase'
            default: return "Payment for Additional VLO Works"
        }
    }

    const downloadInvoice = async () => {
        setLoading(true)
        try {
            const { status, data } = await customAxios.get(`company/invoices/${invoiceInfo?.id}/download`, {
                responseType: 'blob',
            })
            if (status === 200) {
                const url: string = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `invoice-${id}.pdf`);
                document.body.appendChild(link);
                link.click();
            }
        } catch (e) {}
        setLoading(false)
    }

    const getPaymentMethod = () => {
        let payment_method = 0;
        if (method === 'personal' && paymentType === 'card') {
            payment_method = 0
        } else if (method === 'corporate' && paymentType === 'card') {
            payment_method = 1
        } else if (method === 'corporate' && paymentType === 'transfer') {
            payment_method = 2
        } else if (method === 'personal' && paymentType === 'transfer') {
            payment_method = 3
        } else if (method === 'corporate' && paymentType === 'crypto') {
            payment_method = 4
        } else if (method === 'personal' && paymentType === 'crypto') {
            payment_method = 5
        }

        return payment_method

        /*try {
            const {} = await customAxios.put(`/billing-info/provider`, {
                payment_method
            })
        } catch (e) {}*/
    }

    return (
        <div className="checkout">
            <div className="checkout__left">
                <div className="checkout__container">
                    <div className="checkout__header">
                        <div className={'checkout__header-name'}>
                            <h2>Invoice#</h2>
                            <h3>{invoiceInfo?.id}</h3>
                        </div>
                        {(invoiceInfo?.xero_invoice_id) && <a onClick={downloadInvoice} className="checkout__header-download">
                            <span>Download PDF</span>
                            <div className="checkout__header-download-icon">
                                <img src={downloadIcon} alt=""/>
                            </div>
                        </a>}
                    </div>
                    <div className="checkout__description">
                        <div className={'checkout__description-row'}>
                            <div className="checkout__description-name">
                                Service
                            </div>
                            <div className="checkout__description-value">
                                Assistance with Sales of Add-ons
                            </div>
                        </div>
                        <div className={'checkout__description-row'}>
                            <div className="checkout__description-name">
                                Created On
                            </div>
                            <div className="checkout__description-value">
                                {dateFormatter(invoiceInfo.created_at)}
                            </div>
                        </div>
                        {invoiceInfo.due_date && <div className={'checkout__description-row'}>
                            <div className="checkout__description-name">
                                Due date
                            </div>
                            <div className="checkout__description-value">
                                {dateFormatter(invoiceInfo.due_date)}
                            </div>
                        </div>}
                        {(invoiceInfo.status === 'PAID' || invoiceInfo.status === 'UNPAID') && <div className={'checkout__description-row'}>
                            <div className="checkout__description-name">
                                Status
                            </div>
                            <div className="checkout__description-value">
                                {invoiceInfo.status}
                            </div>
                        </div>}
                        <div className={'checkout__description-row'}>
                            <div className="checkout__description-name">
                                Description
                            </div>
                            <div className="checkout__description-value task-body__description">
                                This payment covers the assistance provided by Legal Nodes with the sale of the Service Provider’s Add-ons (the Service Provider’s Services) and the facilitation of the relationship between the Client and the Service Provider via the Legal Nodes platform.                            </div>
                        </div>
                    </div>
                    <div className="checkout__total">
                        <div className="checkout__total-name">
                            Total (VAT not included)
                        </div>
                        <div className="checkout__total-value">
                            ${invoiceInfo.sum}
                        </div>
                    </div>
                </div>
                {invoiceInfo?.status === "UNPAID" && <div className={'checkout__container mt10 checkout__form-pb60'}>
                    <div className="checkout__heading">
                        Choose Payment Method
                    </div>
                    <div className="checkout__checkbox-container">
                        <p className={'checkout__checkbox-container-text'}>Please choose one of the following payment methods:</p>
                    </div>
                    <div className="checkout__checkbox" onClick={() => setMethod(method === 'personal' ? 'corporate' : 'personal')}
                    >
                        <span>PERSONAL</span>
                        <div
                            className={`checkout__checkbox__container ${method !== 'personal' && 'checkout__checkbox__container-active'}`}>
                            <div className="checkout__checkbox-active" />
                        </div>
                        <span>CORPORATE</span>
                    </div>
                    <div className="checkout__payment-types">
                        <div className={'checkout__country'}>
                            <div><span>Your selected country:</span> <b>{all_countries.find(el => el.code === (selectedCountry || 'GB'))?.name}</b> <button onClick={() => setIsOpenSelectCountryModal(true)} className={'checkout__country-button'}>Change</button></div>
                        </div>
                        {/*{stripeCountries.find(el => el.code === selectedCountry) && */}
                            <div className="checkout__payment-type" onClick={() => setPaymentType('card')}>
                            <div className={'checkout__payment-type-container'}>
                                <RadioItem active={paymentType === 'card'}/>
                                <img className="checkout__payment-type-image" src={personalIcon} alt=""/>
                                <span className={'checkout__payment-type-name'}><span
                                    className={'text-uppercase'}>{method}</span> CREDIT CARD/DEBIT CARD</span>
                            </div>
                            {paymentType === 'card' && <MethodDescription type={'card'} method={method}/>}
                        </div>
                        <div className="checkout__payment-type" onClick={() => setPaymentType('transfer')}>
                            <div className="checkout__payment-type-container">
                                <RadioItem active={paymentType === 'transfer'} />
                                <img className="checkout__payment-type-image" src={bankIcon} alt=""/>
                                <span className={'checkout__payment-type-name'}>Bank Transfer from <span className={'text-uppercase'}>{method}</span> Account</span>
                            </div>
                            {paymentType === 'transfer' && <MethodDescription type={'transfer'} method={method} />}
                        </div>
                        {/*<div className="checkout__payment-type" onClick={() => setPaymentType('crypto')}>
                            <div className="checkout__payment-type-container">
                                <RadioItem active={paymentType === 'crypto'} />
                                <img className="checkout__payment-type-image" src={cryptoIcon} alt=""/>
                                <span className={'checkout__payment-type-name'}>Cryptocurrency Transfer from <span className={'text-uppercase'}>{method}</span> Wallet</span>
                            </div>
                            {paymentType === 'crypto' && <MethodDescription type={'crypto'} method={method} />}
                        </div>*/}

                        {window.location.search === '?test_payment=true' && <div className="checkout__payment-type" onClick={() => setPaymentType('test')}>
                            <div className="checkout__payment-type-container">
                                <RadioItem active={paymentType === 'test'} />
                                <img className="checkout__payment-type-image" src={bankIcon} alt=""/>
                                <span className={'checkout__payment-type-name'}>TEST</span>
                            </div>
                        </div>}
                    </div>
                    <button
                        //disabled={!stripeCountries.find(el => el.code === selectedCountry) && paymentType === 'card'}
                        onClick={() => {
                        setModalType('confirm_provider_billing_data')
                        setModalData({ company_id: invoiceInfo?.company?.id, paymentType, method, selectedCountry, payment_method: getPaymentMethod() })
                    }} className={'checkout__button btn-black'}>
                        go to payment
                    </button>
                </div>}
                {/*{paymentType === 'transfer' && <BankTransfer />}
                {paymentType === 'card' && <PaymentByCard />}*/}
            </div>
            <div style={{ opacity: 0 }} className="checkout__right checkout__container" />
            <SelectBillingCountry
                initialValue={selectedCountry}
                isOpen={isOpenSelectCountryModal}
                onChange={(countryCode) => {
                    setSelectedCountry(countryCode)
                    setIsOpenSelectCountryModal(false)
                }}
                close={() => {
                    setIsOpenSelectCountryModal(false)
                }}
            />
        </div>
    )
}