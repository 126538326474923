import "./book-new-follow-up.scss"
import {FollowUpTabs} from "../components/FollowUpTabs/FollowUpTabs";
import {BookNewFollowUpTypesItemType, useBookNewFollowUp} from "./useBookNewFollowUp";
import React from "react";
import soonIcon from "./icons/arrow.png"
import {ByNewCallModal} from "../components/ByNewCallModal/ByNewCallModal";
import Loader from "../../../components/other/Loader";
import {ModalLoader} from "../../../components/other/ModalLoader/ModalLoader";
import {CustomLoader} from "../../../components/UI/CustomLoader/CustomLoader";

export const BookNewFollowUp = () => {
    const {
        items,
        isOpenByNewCallModal,
        setIsOpenByNewCallModal,
        loader
    } = useBookNewFollowUp()

    return (
        <div className={"follow-up-list"} style={{ flexDirection: "column" }}>
            <h1>Expert Calls Hub</h1>
            <div className={"w100"}>
                <FollowUpTabs />
                <div className={"book-new-follow-up"}>
                    <h2>
                        choose a call, redeem credits and schedule it at your convenience <br />
                        within 1 month and while your contract is active
                    </h2>
                    {loader && <CustomLoader />}
                    {!loader && <div className="book-new-follow-up__items">
                        {
                            (items || []).map(item => (
                                <BookNewFollowUpItem
                                    key={item.id}
                                    item={item}

                                    onClick={() => setIsOpenByNewCallModal({
                                        isOpen: true,
                                        item
                                    })}
                                />
                            ))
                        }
                        <div className="book-new-follow-up__item"
                             style={{display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                            <div className="book-new-follow-up__item-icon">
                                <img width={30} src={soonIcon} alt=""/>
                            </div>
                            <div className={"w100"}>
                                <b>More coming soon!</b>
                                <p>
                                    Let us know which key experts you’d love to see.
                                    Your feedback is important to us and helps us ensure that all your needs are met.
                                </p>
                            </div>
                            <a style={{ textDecoration: "none" }} target={"_blank"} href="https://form.typeform.com/to/nAl19bNW?typeform-source=legalnodes.atlassian.net">
                                <button style={{background: "black"}}>
                                    leave feedback
                                </button>
                            </a>

                        </div>
                    </div>}
                </div>
            </div>

            <ByNewCallModal
                isOpen={isOpenByNewCallModal.isOpen}
                close={() => setIsOpenByNewCallModal({
                    isOpen: false,
                    item: null
                })}
                item={isOpenByNewCallModal.item}
            />
        </div>
    )
}

const BookNewFollowUpItem: React.FC<{ item: BookNewFollowUpTypesItemType, onClick: () => void }> = ({ item, onClick }) => {
    const htmlString = item.description
    const listItems = Array.from(htmlString.matchAll(/<li>(.*?)<\/li>/g)).map(match => match[1].trim());

    return (
        <div className="book-new-follow-up__item">
            <div className="book-new-follow-up__item-icon">
                <img src={item.image} alt=""/>
            </div>
            <h4>{item.title}</h4>
            <h5>{item.subtitle}</h5>
            <hr />
            <div className="book-new-follow-up__item-ul">
                <div dangerouslySetInnerHTML={{ __html: htmlString }} />
            </div>
            <h6>{item.credit_price} CREDIT{+item.credit_price > 1 && "s"} / {item.duration}</h6>
            <button onClick={onClick}>
                book a call
            </button>
        </div>
    )
}