import React, {useEffect} from "react";
import "./payment-plan-credits.scss"
import {creditRangeBundlesType, CreditsBundle} from "../PaymentPlan";
import paymentPlanCreditIcon from "../../../../../assets/images/paymentPlanCreditIcon.svg"
import iconOk from "../../../../../assets/images/ok-green.svg";
import logoIcon from "../../../../../assets/images/logo.svg"
import "../PaymentPlanItems/payment-plan-items.scss"
import ModalContainer from "../../../../../components/ModalContainer";
import {useLocation} from "react-router-dom";

type Props = {
    creditsCount: number
    updateCredits: (key: number) => void
    createInvoice: (key: number, ib: boolean, id?: number) => void
    creditBundle: CreditsBundle[]
    creditSum: number
    creditBundleItems: creditRangeBundlesType[]
}

export const PaymentPlanCredits: React.FC<Props> = ({ creditBundleItems, creditSum, creditsCount, updateCredits, createInvoice, creditBundle }) => {
    const [isModalOpen, setIsModalOpen] = React.useState(0)
    const [bundleId, setBundleId] = React.useState<number | null>(null)
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);

    useEffect(() => {
        if (searchParams.get("count")) {
            updateCredits(+(searchParams.get("count") || 1))
        }
    }, []);

    const decCredits = () => {
        if (creditsCount > 1) {
            updateCredits(creditsCount - 1)
        }
    }

    const incCredits = () => {
        updateCredits(creditsCount + 1)
    }


    const findItemByCreditsCount = () => {
        return (creditBundleItems || []).find((item: creditRangeBundlesType) => creditsCount >= item?.range_count_from && creditsCount <= item?.range_count_to);
    };

    const findNextPlan = () => {
        let result = -1
        if (findItemByCreditsCount()) {
            const currentIndex = (creditBundleItems || []).findIndex((item: creditRangeBundlesType) => creditsCount >= item?.range_count_from && creditsCount <= item?.range_count_to)
            if (currentIndex < creditBundleItems.length - 1) {
                return currentIndex + 1
            }
        } else {
            return 0
        }
        return result;
    }

    return (
        <div className={'payment-plan-credits'} id={'payment-plan-credits'}>
            {/*<div className="payment-plan-credits__heading">
                <h2>Buy Credits</h2>
                <p>Choose one of our bundles or select your preferred credit amount.</p>
            </div>*/}
            {/*<div className="payment-plan-credits__items">
                {
                    creditBundle.map(item => (
                        <div key={item.title} className="payment-plan-credits__item">
                            <div style={{ background: '#FEBC95' }} className="payment-plan-credits__item-heading">
                                <p>{item.count} credits</p>
                                <b>${item.price}</b>
                            </div>
                            <div className="payment-plan-credits__item-body">
                                <p>{item.description}</p>
                                <button onClick={() => createInvoice(item.count, true, item.id)} className={'text-uppercase btn-black'}>BUY CREDITS</button>
                            </div>
                        </div>
                    ))
                }
            </div>*/}
            <div className={"payment-plan-items"}>
                <h4>Buy Credits</h4>
                <p>Choose one of our bundles or select your preferred credit amount.</p>
                <div className="payment-plan-items__items">
                    {
                        (creditBundle || []).map(item => (
                            <div className="payment-plan-items__item">
                                <div className="payment-plan-items__item-heading">
                                    <h5><span style={{ fontSize: "21px" }}>{item.count}</span> credits</h5>
                                    <b>${item.price}</b>
                                </div>
                                <p>
                                    {item.description}
                                </p>
                                <button onClick={() => {
                                    if (item?.contract_extension_months === 0) {
                                        createInvoice(item.count, true, item.id)
                                    } else {
                                        setIsModalOpen(item.count)
                                        setBundleId(item.id)
                                    }
                                }}>BUY CREDITS</button>
                                <div className="payment-plan-items__save">
                                    {item?.discount_percents > 0 && <div>Save <b>{item?.discount_percents}%</b></div>}
                                </div>
                                <div className="payment-plan-items__description">
                                    {item?.contract_extension_months > 0 && <em><b>+{item?.contract_extension_months} month</b> contract extension</em>}
                                    {item?.contract_extension_months > 0 && <em><img src={iconOk} alt=""/>UNUSED CREDITS ROLL OVER </em>}
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className="payment-plan-credits__count">
                <div className={"flex flex-center"}>
                    <div className="payment-plan-credits__credits">
                        <span>Credits:</span>
                        <button onClick={decCredits} className={'payment-plan-credits__credits-button'}>-</button>
                        <div className={'payment-plan-credits__input'}>
                            {creditsCount}
                        </div>
                        <button onClick={incCredits} className={'payment-plan-credits__credits-button'}>+</button>
                    </div>
                    {findItemByCreditsCount()?.contract_extension_months && (findItemByCreditsCount()?.contract_extension_months || 0) > 0 ? <div className={"flex flex-center"}>
                        <em className="payment-plan-credits__contract">
                            <b>+{findItemByCreditsCount()?.contract_extension_months} month</b> contract extension
                        </em>
                        <em className="payment-plan-credits__contract">
                            <img src={iconOk} alt=""/> UNUSED CREDITS ROLL OVER
                        </em>
                    </div> : null}
                </div>
                <div>
                    <div className="payment-plan-credits__price">
                        {findItemByCreditsCount()?.discount_percents && (findItemByCreditsCount()?.discount_percents || 0) > 0 ? <div className="payment-plan-credits__save">
                            Save <em>{(findItemByCreditsCount()?.discount_percents || 0)}%</em>
                        </div> : null}
                        <b>${(findItemByCreditsCount()?.range_price_per_credit || creditSum) * creditsCount}</b>
                        <button onClick={() => {
                            if ((findItemByCreditsCount()?.contract_extension_months || 0) > 0) {
                                setIsModalOpen(creditsCount)
                            } else {
                                createInvoice(creditsCount, false)
                            }
                        }}>BUY CREDITS</button>
                    </div>
                </div>
            </div>
            {findNextPlan() >= 0 && creditBundleItems[findNextPlan()] && creditBundleItems[findNextPlan()].contract_extension_months > 0 && <div className={"payment-plan-credits__sales-text"}>
                {creditBundleItems[findNextPlan()].discount_percents === 0 && <div>
                    Buy <b>{creditBundleItems[findNextPlan()]?.range_count_from} credits and get a <b>+{creditBundleItems[findNextPlan()]?.contract_extension_months} month</b> contract extension,
                    and unused credits roll over! </b>
                </div>}
                {creditBundleItems[findNextPlan()].discount_percents > 0 && <div>Buy <b>{creditBundleItems[findNextPlan()]?.range_count_from} credits</b> at a discounted price
                    (<b>save {creditBundleItems[findNextPlan()]?.discount_percents}%</b>), get
                    a <b>+{creditBundleItems[findNextPlan()]?.contract_extension_months} month</b> contract extension,
                    and unused credits roll over!</div>}
            </div>}

            <div className={"follow-up-modal"}>
                <ModalContainer isOpen={isModalOpen !== 0} close={() => {
                    setIsModalOpen(0)
                    setBundleId(null)
                }} heading={""}>
                    <div className={"payment-plan-credits__contract-modal"}>
                        <img src={logoIcon} alt=""/>
                        <h2>
                            Your contract will be extended with this credits purchase!
                        </h2>
                        <p>
                            Your contract will be extended within <b>24 hours after your credits purchase</b>.
                            All unused credits from the previous period will roll over and remain available
                            until the end of your extended contract period.
                        </p>
                        <button onClick={() => {
                            if (bundleId) {
                                createInvoice(isModalOpen, true, bundleId)
                            } else {
                                createInvoice(isModalOpen, false)
                            }
                        }} className={"w100 btn-black text-uppercase mt25"}>BUY CREDITS</button>
                    </div>
                </ModalContainer>
            </div>
        </div>
    )
}