import React, {useRef, useState} from "react";
import {useOnClickOutside} from "../../../../hooks/useOnClickOutside";
import filterIcon from "../../../../assets/images/filter.svg"
import {CSSTransition} from "react-transition-group";
import "./SortKanbanBoard.scss"

const sortParams = [
    { value: '0', label: 'By request name' },
    /*{ value: '-ORDER_BY_NAME', label: 'By project name DESC' },*/
    { value: '1', label: 'By company name' },
    /*{ value: '-ORDER_BY_COMPANY_NAME', label: 'By company name DESC' },*/
    { value: '2', label: 'By unread messages' },
    { value: '3', label: 'By offer id' },
]

export const SortKanbanBoard: React.FC<{ onClick: (key: string) => void, arr?: { value: string, label: string }[], id?: string, localStorageKey?: string }> = ({ onClick, arr = sortParams, id, localStorageKey }) => {
    const [isOpen, setIsOpen] = useState(false)
    const dropdownRef = useRef<HTMLDivElement>(null)
    useOnClickOutside({ ref: dropdownRef, handler: () => setIsOpen(false) })

    const current = sortParams.find(el => el.value === (localStorage.getItem(localStorageKey || 'manager-kanban-sort-value') || sortParams[1].value))
    return (
        <div className={"sort_kanban_board"} ref={dropdownRef} id={id || 'undefined-id'}>
            <div className={"sort_kanban_board__current"} onClick={() => setIsOpen(true)}>
                <div>
                    <em>Filter by:</em> <b>{current?.label}</b>
                </div>
                <img src={filterIcon} alt=""/>
            </div>
            <CSSTransition in={isOpen} timeout={{ enter: 200, exit: 200 }} classNames={'my-node'} unmountOnExit>
                <div className={"sort_kanban_board__dropdown"}>
                    {
                        arr.map(item => (
                            <div
                                className={`sort_kanban_board__dropdown-el ${current?.value === item.value && "sort_kanban_board__dropdown-el-active"}`}
                                key={item.value}
                                onClick={() => {
                                    localStorage.setItem(localStorageKey || 'manager-kanban-sort-value', item.value)
                                    onClick(item.value)
                                    setIsOpen(false)
                                }}
                            >
                                {item.label}
                            </div>
                        ))
                    }
                </div>
            </CSSTransition>
        </div>
    )
}

export const CustomSortDropDown: React.FC<{ onClick: (key: string) => void, arr?: { value: string, label: string }[], value: string }> = ({ onClick, arr = sortParams, value }) => {
    const [isOpen, setIsOpen] = useState(false)
    const dropdownRef = useRef<HTMLDivElement>(null)
    useOnClickOutside({ ref: dropdownRef, handler: () => setIsOpen(false) })

    const current = arr.find(el => el.value === value)
    return (
        <div className={"sort_kanban_board"} ref={dropdownRef}>
            <div className={"sort_kanban_board__current"} onClick={() => setIsOpen(true)}>
                <div>
                    <em>Sort by:</em> <b>{current?.label}</b>
                </div>
                <img src={filterIcon} alt=""/>
            </div>
            <CSSTransition in={isOpen} timeout={{ enter: 200, exit: 200 }} classNames={'my-node'} unmountOnExit>
                <div className={"sort_kanban_board__dropdown"}>
                    {
                        arr.map(item => (
                            <div
                                className={`sort_kanban_board__dropdown-el ${current?.value === item.value && "sort_kanban_board__dropdown-el-active"}`}
                                key={item.value}
                                onClick={() => {
                                    onClick(item.value)
                                    setIsOpen(false)
                                }}
                            >
                                {item.label}
                            </div>
                        ))
                    }
                </div>
            </CSSTransition>
        </div>
    )
}